<template>
    <div>
        <div class="graphDiv shadow" :class="graphClass">
        <a href="#" @click.prevent="setGraphSize(graphSize===2?0:2)">
            <i class="fas fa-chart-bar px-2 m-1 text-success" style="font-size:1.5rem;"></i>
        </a>

            <a href="#" @click.prevent="setGraphSize(1)">
                <i class="fas fa-window-maximize" style="font-size:.6rem;"></i>
            </a>
            <a href="#" @click.prevent="setGraphSize(2)">
                <i class="fas fa-window-maximize" style="font-size:.9rem;"></i>
            </a>
            <a href="#" @click.prevent="setGraphSize(3)">
                <i class="fas fa-window-maximize" style="font-size:1.2rem;"></i>                
            </a>
            <a href="#" @click.prevent="setGraphSize(0)" class="m-1"><i class="fas fa-window-minimize"></i></a>

            <select v-model="countColumn" @change="showGraph">
                <option v-for="(item,id) in statFuns" v-bind:value="item.id" :key="id">
                    {{ item.title }}
                </option>
            </select>

            <span class="px-3">
                    <input type= "checkbox"  v-model="average" @change="showGraph">
                    Průměr 
            </span>


            <canvas id="bar-chart" width="800" height="350"></canvas>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        &nbsp;
    </div>
</template>



<script>
// import mixin from 'mixin.js';

    export default {
      props: {
        statPivots:Array,       
        statFuns:Array, //asi není třeba
        history:Array, 
        selectedColumn:String,
       },

    data: function() {
        return {
            average:false, // zda je přepnuto na průměrnou cenu
            graphData:{},   //data pro graf
            chart:null,
            countColumn:'count', //dle statFuns
            //barvy pro jednotlivé linie
            borderColors:[
                "blue","rgb(179, 0, 31)","purple", "#3e95cd","#8e5ea2","#3cba9f","#e8c3b9","#c45850",'green','orange','black','brown','silver'
            ],
            graphSize:0,    //velikost grafu, 1 = 30, 2 = 50, 3=80, 0 = hide
        };
    },

    computed: {

    }, //computed

    methods: {

        setGraphSize:function(size) {
            if (size>-1 && size<4) {
                this.graphSize = size;
            }
        },



        consolidateGraphData:function() {
            //připraví data pro daný typ grafu

            //pro všechna data v history 

            //vytvoří array s pivoty ... pokud nebudou stejné, přidá... na zodpovědnosti usera

            //labels musí odpovídat datům, resp kde nic není  = 0
            //


        },

        getAllPivots: function() {

            var merged = {};
            this.history.forEach((h)=>{
                merged = {...merged, ...h.pivots};
            });
            return merged;
        },

        getPivotData: function(pivots) {
            var data=Object.keys(this.history);
            const keys  = Object.keys(pivots)
            this.history.forEach((h, i)=>{
                data[i]=({
                    data:[],
                    label:h.title,
                    backgroundColor:this.borderColors[i],
                    fill:false,
                });
                // console.log('data');
                // console.log(data);
                // console.log('pivots');
                // console.log(pivots);

                const d = [];

                for (const pivot of keys) {
                    if (h.stat[0][pivot]=== undefined) {
                        // data[i]['data'][pivot] = 0;
                        d.push(0);
                    } else {
                        
                        if (this.average) {
                            if (h.stat[0][pivot]['count']>0) {
                                d.push(Math.round(h.stat[0][pivot][this.countColumn]/h.stat[0][pivot]['count']));

                            } else {
                                d.push(0);

                            }

                        } else {
                            d.push(h.stat[0][pivot][this.countColumn]);
                        }
                        //data[i]['data'][pivot] = h.stat[0][pivot]['count'];
                    }
                    // data[i]['data'] = Object.values(data[i]['data']);

                    data[i]['data'] = d;

                    // console.log(h.stat[0][pivot]['count']);
                }


                // pivots.forEach(const [key, value] of Object.entries(object1)
                //     (pivot, key)=>{
                // });
            });
            // console.log('data');
            // console.log(data);
            return data;
        },
        showGraph: function() {

            if (this.history===null) {return;}

                let pivots = this.getAllPivots();
                let datasets = this.getPivotData(pivots);
                // console.log ('SHOW graph');
                // console.log (pivots);
                // console.log (datasets);
                if (this.chart !== null) {
                    this.chart.destroy();
                }
                //načti všechna data do array
                this.chart =  new Chart(document.getElementById("bar-chart"), {
                  type: 'bar',
                  data: {
                    labels: Object.values(pivots),
                    datasets: datasets,


                  },
                  options: {
                    title: {
                      display: true,
                      text: 'Graf za ... '
                    },

                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                            }
                        }]
                    },

                    plugins: {
                      datalabels: {
                        color: "black",
                        backgroundColor:"silver",
                        opacity:.7,
                        textAlign: "center",
                        font: {
                          weight: 700,
                          size:11,
                        }
                      }
                    },                    
                  }
                });

        },

    }, // METHODS


      created:function(){
        this.countColumn = this.selectedColumn;
        // console.log(stat);
        // console.log(stattitle);
      }, //CREATED

      mounted:function(){
        this.showGraph();
      }, //MOUNTED

      computed:{

        graphClass: function(){
            var s = '';
            switch (this.graphSize) {
                case 0:
                    s = 'graphDivHide';
                    break;
                case 1:
                    s = 'graphDivSmall';
                    break;
                case 3:
                    s = 'graphDivExtended';
                    break;
                default:
                    s = '';
                    break;
            }

            //pokud není nic v historii
            if (this.history===null || this.history.length===0) {
                s='graphDivDoNotShow';
            }

            return s;
        },

      },

      watch:{
        selectedColumn:function() {
            this.countColumn = this.selectedColumn;
            this.showGraph();
        },

        history: function() {
            if (this.chart) {this.chart.destroy();}
                this.showGraph();
        },
      }, //WATCH

    }
</script>

<style>

.graphDiv {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 50%;
    background-color:white;
    border:1px solid black;
    z-index:2000;
}


.graphDivExtended {
    width: 90%;
}

.graphDivSmall {
    width: 30%;
}

.graphDivHide {
    height:2.5rem;
    width: 20rem;
    right: 5rem;
    background-color:silver;
}

.graphDivHide canvas{
    visibility:hidden;
}

.graphDivDoNotShow canvas{
    visibility:hidden;
}

.graphDivDoNotShow {
    visibility:hidden;
}
</style>
