<template>
    <div>



        <div class="pt-2">
            <div class="c-btn-category" :class="{'c-btn-category-active':activeCat===cat.id}"  v-for="cat in categories" :key="cat.id" @click="activeCat=cat.id" @mouseover="activeCat=cat.id" @mouseout="activeCat=0">
                <div class="row">
                   <div class="col-xl-1 col-lg-2 col-md-3">
                       <div class="p-2 pl-3">
                           <b>

                            <nobr>
                                <button class="btn btn-sm btn-primary" v-if="edit" @click="newButton(cat)"><i class="far fa-plus-square"></i></button>
                                {{ cat.title }}
                            </nobr>
                           </b>
                       </div>
                    </div>
                    <div class="col-xl-11 col-lg-10 col-md-9 c-btn-container">
                        <div class="c-btn" :class="{'c-btn-selected':isSelected(b.id)!==false}" v-for="b in cat.buttons" :key="b.id" @click="clickButton(b, cat)" v-show="isSelected(b.id)!==false||activeCat===cat.id">
                            {{ b.title }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="c-btn-category mb-1">
                <div class="row">
                    <div class="col">
                        <div class="c-btn-category text-center">
                            <div class="d-btn" :class="{'d-btn-inactive':showButton}" @click="loadOffer">
                                Načíst vozidla
                            </div>
                        </div>

                    </div>
                    <div class="col" v-if="admin===true">
                        <div class=" c-btn-category text-right mb-3">
                            <div class="btn-new"  @click.stop="clearButons">
                                <i class="far fa-trash-alt"></i>
                                Vymazat
                            </div>
                            <div class="btn-new" :class="{'btn-secondary':edit}" @click.stop="changeEditMode">
                                <i class="fas fa-edit"></i>
                                Úprava
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <modal-component v-if="showModal" @close="showModal = false" modal-class="w-50">
                <!--
            you can use custom content here to overwrite
            default content
            -->
            <h3 slot="header">{{ category.title }}<span v-if="button.id!==0"> - nová</span></h3>
            <div slot="body">
                        Název <input name="title" v-model="button.title">
                        <br>
                        Pořadí <input name="order_by" v-model="button.order_by">
                        <br>
                        <div v-if="button.type==1">
                            <div class="float-right">
                                <nobr>
                                    Filtr <input name="item_filter" v-model="item_filter">
                                </nobr>

                            </div>
                            <h2>Položky</h2>
                            <div class="alert alert-info">
                                <template v-for="(cat,key) in category.allItems">
                                    <label  :key="key" class="mx-2" v-if="matches(cat,item_filter)"><input name="selected_items" type="checkbox" v-model="button.selected_items"  :value="key">
                                        {{ cat }}
                                    </label>
                                </template>
                            </div>
                        </div>
                        <div v-else-if="button.type==2">
                            <h2>Rozmezí</h2>
                            <ul>
                                <input name="range-from" v-model="button.from">
                                -
                                <input name="range-to"  v-model="button.to">
                            </ul>

                        </div>

            </div>
            <div slot="footer">
                <button class="btn btn-primary" @click="saveButton">
                    Uložit
                </button>
                <button class="btn btn-primary">
                    Nový
                </button>
                <button class="btn btn-secondary" @click.prevent="showModal=false">
                    Zrušit
                </button>
            </div>
        </modal-component>

    </div>

</template>



<script>
    export default {

      props: {
        selectedButtons:Array,
        demandButtons:Array,
        categories:Array,
        showButton:Boolean,
        carentryinit:String,
        demandType:0,
        admin:Boolean,
       },
      data: function() {
          return {
              showModal:false, //zobrazit modal
              carentry:"",  //fulltextové hledání
              edit:false, //zda editační mod
              activeCat:1,
              button:{}, //pro editaci
              category:{}, //pro editaci
              item_filter:"",
            change:0,
        }
      },

        computed: {
        },

        mounted: function() {
            //nastaví první kategorii jako aktivní
            if (this.categories && this.categories.length>0) {
                this.activeCat = this.categories[0].id;
            }
            if (this.carentryinit>"") {
                this.carentry = this.carentryinit;
            }
            this.change = 0;
        },

        watch:{
        },

        methods: {


            changeEditMode:function(){

                this.edit=!this.edit;
                this.$emit('open-tab',2)
            },

            matches: function(heap, needle){
                return (!needle>"")||  heap.toLowerCase().includes(needle.toLowerCase());
                return true;
                 //||  heap.toLowerCase().includes(needle.toLowerCase());
            },

            //zda je tlačítko vybráno
            isSelected: function(id) {
                if (this.selectedButtons) {
                    return  this.selectedButtons.indexOf(id)!==-1;
                }
                return false;
            },

            loadOffer: function() {
                if(!this.showButton) {
                    this.activeCat=null;
                    this.$emit('load-offer');
                }

            },

            //najde nejvyšší index ORDER_BY
            getMaxOrderBy: function(cat){
                let x = 0;
                cat.buttons.forEach((b)=>{ if(b.order_by>x){ x=b.order_by; }})
                return x;
            },

            newButton: function(cat) {
                if (!this.button) {
                    this.button = {};
                }
                this.button.title = 'název';
                this.button.id = 0;
                this.button.category_id=cat.id;
                this.button.values = [];
                this.button.type = cat.type;
                this.button.order_by = this.getMaxOrderBy(cat)+1;
                this.category = cat;

                if (cat.type==1) {
                    this.button.selected_items = [];
                    this.button.items = cat.allItems;
                }
                if (cat.type==2) {
                    this.button.from = 0;
                    this.button.to = 0;
                }


                this.showModal = true;
            },

            clickButton:function(button, cat) {
                if (this.edit) {
                    this.editButton(button, cat);
                    } else {
                    this.toggleBtn(button.id);
                }
            },

            editButton: function(button, cat) {

                this.button = button;
                this.category = cat;
                this.button.type = cat.type;
                let v = (button.values); //JSON.parse 
                if (cat.type==1) {
                    this.button.selected_items = v;
                    this.button.items = cat.allItems;
                }
                if (cat.type==2) {
                    this.button.from = v[0];
                    this.button.to = v[1];
                }

                this.showModal = true;
                console.log('editace',this.button);

            },

            toggleBtn: function(id){
                //pokud není v seznamu - přidej ho tam, jinak ho odeber
                let i = this.selectedButtons.indexOf(id);
                if(i===-1) {
                    this.selectedButtons.push(id);
                } else {
                    this.selectedButtons.splice(i,1)
                }
                this.change=1;

                this.$emit('button-change', this.selectedButtons);
            },

            clearButons:function(){
                this.$emit('button-clear', );
                this.change=1;

            },

            saveButton:function () {
                this.button.category_id = this.category.id;
                this.fetchData('save',this.button);
                this.showModal = false;
                this.item_filter = "";
                this.$emit('updateCategory');
            },

            //přines data z DB
            fetchData:function(command, data) {
                let inputData = {command:command, api_id:'demandButtonApi'};
                let results = {   apiData:'data', successApi:'success', errors:'errors', };

                switch (command) {
                
                    case 'save': //tlačítka
                            inputData.button = this.button;
                            //console.log('UPDATE', this.button);
                            results.button = "button";
                        break;
                
                    default:
                        break;
                }
                
                var d = this.getApiData( 
                            inputData,
                            results
                );

            },



        },
        

    }
</script>


<style>
    .c-btn-category {
        background-color:white;   
        margin: 0px 15px;     
        min-height: 2.3rem;
    }
    .c-btn-container {
        display: flex;
        flex-wrap: wrap;

        align-items: center;

    }
    .c-btn-category-active{
        background-color:silver;
        transition: background-color .4s;

    }
    .c-btn {
        min-width: 8rem; 
        padding-left:1.2rem;
        padding-right: .2rem;
        
         height: 1.9rem;
        line-height: 1.9rem;
        overflow: hidden;
        position: relative;
        align-items: center;

        border-radius: 5px;
        margin:.2em;

        font-size: 1.05rem;
        display: inline-block;
        text-align: center;
        background-color: rgb(200, 238, 215);
        color: rgb(114, 114, 114);
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    }

    .c-btn-category-active .c-btn {
        /* height: 3rem;
        line-height: 3rem; */
        transition: .4s;
    }


    .c-category-title {
        width: 8rem;
        font-weight: 500;
        display: inline-block;
        color: grey;
    }

    .c-btn-selected {

        background-color: #448e24;
        color: white;
        box-shadow:0;

        }

    .c-btn-selected:before {
        content: '\f14a';
        background-color: #448e24;
        color: blue;
        font-family: "Font Awesome 5 Free";
        position: absolute;
        top:0;
        left:3px;
    }

    .d-btn {
        background-color: #448e24;
        color: white;
        cursor: pointer;
        height: 3rem;
        line-height: 2rem;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        padding: .5rem;
        width: 15rem;
        margin: 1rem;
        padding:.5rem;
        transition: 1s;
        display: inline-block;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;        
        border: 1px solid white;
        border-radius: 5px;
    }


    .d-btn-inactive {
        background-color: silver;
        color: black;
        transition: 1s;
        cursor: not-allowed;
    }


#carentry {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
     border-radius: 20px;
     border: 2px solid rgb(85, 85, 85);
     color: #a0d18c;
     width: 17em;
     height: 1.8em;
     font-size: 1.1rem;
     padding-left: 10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
#carentry:focus {
     outline: none;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    transition: box-shadow 1s ease-in-out;
}



</style>
