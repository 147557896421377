<template>


                <div class="c-cust" 
                    :class="tabClass" 
                    @click="$emit('active-tab',id)" 

                    @mouseover="$emit('active-tab',id)"
                >
                    <a :name="'tab'+id"></a>
                    <div class="new-title d-flex align-items-center justify-content-between mb-2 position-relative" @click.stop="$emit('toggle-tab',id)">
                            <slot name="title">                                
                            </slot>                  
                    </div>


                        <div :class="contentClass">

                            <transition
                                    name="expand"
                                    @enter="enter"
                                    @after-enter="afterEnter"
                                    @leave="leave"
                                >                            
                                    <slot name="content" v-if="open">
                                            obsah
                                    </slot>
                            </transition>

                            <slot name="closed" v-if="!open">
                            </slot>

                        </div>

                </div>
</template>



<script>
    export default {

      props: {
          active:Boolean, //zda je aktivní či nikoliv - a podle toho i zobrazení
          open:Boolean,
          id:String,
       },

    computed:{
        contentClass: function () {
            return 'c-content-' + (this.open?'open':'closed');
            //pokud je aktivní či nikoliv - pak otevírá zavírá obsah a dává alternativní 
        },        
        tabClass: function () {
            //pokud je aktivní či nikoliv - pak otevírá zavírá obsah a dává alternativní 
            let c = this.active?"c-active ":"";
            c+=this.open?'c-open':'c-closed';
            return c;                    
        },
    },

    methods: {
        click:function() {
              this.$emit('click-title',this.id);
        },
        enter(element) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const height = getComputedStyle(element).height;

            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            // Trigger the animation.
            // We use `requestAnimationFrame` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },

        afterEnter(element) {
            element.style.height = 'auto';
        },
        leave(element) {
            const height = getComputedStyle(element).height;
            
            element.style.height = height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        },



  },


    }
</script>


<style scoped>

.expand-enter-active,
.expand-leave-active {
  transition: height 1s ease-in-out;
    transition-property: opacity, height;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
}




    .c-content-open{
        padding: 0px 1rem;
    }

    .c-content-closed{
        padding: 0px 1rem;
    }

    .c-active {
        background-color: rgb(238, 238, 238);

        -webkit-transition: all 1s ease-out;
        -moz-transition: all 1s ease-out;
        -o-transition: all 1s ease-out;
        transition: all 1s ease-out;
    }



    .new-title {
        /* border-bottom: 1px green solid;
        border-left: 1px green solid; */
        background-color: rgb(211, 243, 215);
        margin: 10px 7px 1.2rem 7px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
        -webkit-transition: all 1s ease-in;
        -moz-transition: all 1s ease-in;
        -o-transition: all 1s ease-in;
        transition: all 1s ease-in;
        border-radius: 5px;
        padding: 0 5px;
    
    }


    .c-active .new-title {
        /* border-bottom: 1px green solid;
        border-left: 1px green solid; */
        background-color:rgb(94, 190, 91);
        color: rgb(128, 0, 0);
        /* box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px; */
        -webkit-transition: all 1s ease-out;
        -moz-transition: all 1s ease-out;
        -o-transition: all 1s ease-out;
        transition: all 1s ease-out;        

    }





</style>
