<template>
    <div v-cloak>
      <div v-if="!$data">
        Loading..... (spinner)
      </div>
        <div v-show="loading">
            
        <slot name="spinner"></slot>
        </div>

        <!-- TOOLBOX -->
        <h1><i class="fas fa-table"></i>
            Hromadná úprava: {{ typeTitle}}
        </h1>

    <div id="accordion">

      <div class="card">
        <div class="card-header alert-info p-0" id="headingOne">
            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <b>
                    1.Volba množiny vozidel
                </b>
            </button>
            <div class="badge bg1 border m-1 p-2" v-if="cars.length>0">{{ cars.length }} vozidel</div>
            <div class="float-right">

                <button class="btn btn-link" @click.prevent="saveToLocalStorage">
                        Uložit
                </button>
                <button class="btn btn-link" v-show="lsSaved" @click.prevent="restoreFromLocalStorage">
                        Obnovit
                </button>
                <span title="Automaticky ukládat poslední nastavení" class="mr-3">
                    <input type="checkbox" v-model="autoSave"> Automaticky
                </span>
                
            </div>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
          <div class="card-body">
             <slot name="filter"></slot>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header p-0 alert-info" id="headingTwo">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <b>         
                    2.Výběr dle parametrů
                </b>
            </button>
            <div class="badge bg1 border m-1 p-2">Vybráno {{ selectedCount }} ze {{ cars.length }}</div>
            <div class="float-right mr-2 p-2">
                    <input type="checkbox" v-model="showSelectedOnly"> Jen vybrané
            </div>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
          <div class="card-body">
                
                <div class="d-inline-block">
                        
                    <button title="Vybrat všechny" class="btn btn-secondary btn-sm" @click.prevent="selectAllVue"  v-show="selectedCount<cars.length">
                            <i class="far fa-check-square"></i>
                    </button>

                    <button title="Zrušit výběr" class="btn btn-secondary btn-sm" @click.prevent="selectNoneVue" v-show="selectedCount>0">
                            <i class="far fa-square"></i>
                    </button>

                    <button title="Invertovat výběr" class="btn btn-secondary btn-sm" @click.prevent="invertSelect"><i class="far fa-minus-square"></i></button>

                </div>
                <div class="d-inline-block">
                    <nobr>
                    <span @click.prevent ="switchSelect">
                        <i class="fa pl-2" :class="{'fa-check-square':select,'fa-square':!select}" aria-hidden="true"></i>
                        {{ select?'Označit':'Odznačit' }}
                    </span>
                    dle hodnoty
                    <select v-model="numericFilter">
                        <option v-for="(item,id) in numericFilters" v-bind:value="item.id">
                            {{ item.title }}       
                        </option>
                    </select>
                        <a href="#" :title="select?'Vybrat':'Odebrat' +' vozy s nižší hodnotou než'" class="btn btn-secondary btn-sm" @click.prevent="selectNumeric(true)"><i class="fas fa-sort-amount-down"></i></a>
                         <input type="number" class="custom" value='500000' style="width:5rem;" v-model="numericValue">
                        <a href="#" :title="select?'Vybrat':'Odebrat' + ' vozy s vyšší hodnotou než'" class="btn btn-secondary btn-sm" @click.prevent="selectNumeric(false)"><i class="fas fa-sort-amount-up"></i></a>


                    </nobr>
                </div>          
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header alert-info  p-0"  id="headingThree">
          <b class="mb-0">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <b>3.Hromadná změna</b>
            </button>
          </b>
            <div class="badge bg1 border m-1 p-2" v-if="changesCount>0">Navrženo {{ changesCount }} změn</div>


            <!-- BUTTONS -->
            <div class="float-right" v-show="changesCount>0">

                <form id="main-form" class="form-horizontal" method="POST" :action="this.action"  enctype="multipart/form-data">
                    <slot name="csrf"></slot>
                    <input id="changeJSON" name="changeJSON" type="hidden" :value="changeJSON" >
                  <center class="p-1 pr-3">
                    <button id="changeButton" name="changeButton" value="change" class="btn btn-primary btn-sm" @click="sendChanges()" title="Uloží všechny navržené změny do databáze">
                        <i class="fas fa-check"></i> 
                        Uložit změny
                    </button>
                    <button class="btn btn-danger btn-sm" @click.prevent="resetChanges()"><i class="fas fa-times"></i> Zrušit</button>
                  </center>
                </form>
            </div>          


        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
          <div class="card-body">



                <nobr>
                    Změnit 
                    <select v-model="type">
                        <option v-for="(item,id) in types" v-bind:value="item.id">
                            {{ item.title }}       
                        </option>
                    </select>

                     o <input type="number" name="change_input_field" class="custom" style="width:5rem;" v-model="changeValue"> 
                    <select v-model="changeReferenceType">
                        <option v-for="(item,id) in changeReference" v-bind:value="item.id">
                            {{ item.title }}       
                        </option>
                    </select>
                    <a href="#" title="Přepočítat všechny zaškrtlé řádky" class="btn btn-primary btn-sm" @click.prevent="calculate">Přepočítat</a>

                    <span v-if="plusOnly">Kalkuluje pouze kladné hodnoty</span>

                </nobr>

          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header alert-info  p-0"  id="heading4">
          <b class="mb-0">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                <b>4.Statistika</b>
            </button>
          </b>
            <template v-if="changesCount>0">
                <span  class="badge bg1 border m-1 p-2" v-for="s in statFuns" v-if="!s.noChange && stat[1][s.id]!=0"><i class="fa fa-plus-circle" aria-hidden="true"></i> {{ s.title }}  {{ stat[1][s.id] }}Kč </span>
            </template>
        </div>
        <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
          <div class="card-body">
            <table class="simple-table w-100">
                <!-- head -->

                <tr>
                    <th rowspan=2>
                        <select v-model="statType">
                            <option v-for="(item,id) in statPivots" v-bind:value="item.id">
                                {{ item.title }}       
                            </option>
                        </select>
                        <div class="float-right m-1">
                            <small>
                                Průměr <input type= "checkbox"  v-model="average">
                            </small>
                            <br>
                            <small>
                                Rozdíl <input type= "checkbox"  v-model="difference">
                            </small>
                        </div>
                    </th>

                    <th  v-for="fun in statFuns" v-bind:colspan="fun.noChange?1:2"  v-bind:rowspan="fun.noChange?2:1" class="bg1">
                        <div class="w100 text-center border bg1">
                            {{fun.title}}
                        </div>
                    </th>
                </tr>
                <tr>
                    <template v-for="fun in statFuns" v-bind:colspan="fun.noChange?1:2" v-if="!fun.noChange">
                        <th class="text-right">Aktuálně
                        </th>
                        <th class="text-right">Po změně</th>
                    </template>

                </tr>

                <!-- body  TR - pro všechny pivots-->
                  <template v-for="(pivot,index) in pivots">
                    <tr>
                        <th>{{ pivot }}</th>
                        <!-- jednotlivé položky -->
                        <template v-for="fun in statFuns">                      
                            <td v-if="average&&(countColumn!=fun.id)">{{ formatCurrency(stat[0][index][fun.id]/stat[0][index][countColumn]) }}</td>
                            <td v-else>{{ formatCurrency(stat[0][index][fun.id]) }}</td>

                            <template v-if="!fun.noChange">
                                <td v-if="average&&(countColumn!=fun.id)">
                                    <template v-if="difference">
                                        {{ formatCurrency(stat[1][index][fun.id]/stat[0][index][countColumn]) }}
                                    </template>
                                    <template v-else>
                                        {{ formatCurrency((stat[0][index][fun.id] + stat[1][index][fun.id])/stat[0][index][countColumn]) }}
                                    </template>
                                </td>
                                <td v-else>
                                    <template v-if="difference">
                                        {{ formatCurrency(stat[1][index][fun.id])}}
                                    </template>
                                    <template v-else>
                                        {{ formatCurrency(stat[0][index][fun.id] + stat[1][index][fun.id])}}
                                    </template>

                                </td>
                            </template>

                        </template>
                    </tr>
                  </template>               

                <!-- suma  - stat - 0, 1 pro všechny values-->
                <tr>
                        <th>Součet</th>
                        <!-- jednotlivé položky -->
                        <template v-for="fun in statFuns">                      
                            <td v-if="average&&(countColumn!=fun.id)">{{ formatCurrency(stat[0][fun.id]/stat[0][countColumn]) }}</td>
                            <td v-else>
                                {{ formatCurrency(stat[0][fun.id]) }}
                            </td>
                            <template v-if="!fun.noChange">
                                <td v-if="average&&(countColumn!=fun.id)">
                                    <template v-if="difference">
                                    {{ formatCurrency(stat[1][fun.id]/stat[0][countColumn]) }}
                                    </template>
                                    <template v-else>
                                    {{ formatCurrency((stat[0][fun.id]+stat[1][fun.id])/stat[0][countColumn]) }}
                                    </template>
                                </td>
                                <td v-else>
                                    <template v-if="difference">
                                    {{ formatCurrency(stat[1][fun.id]) }}
                                    </template>
                                    <template v-else>
                                    {{ formatCurrency(stat[0][fun.id] + stat[1][fun.id]) }}
                                    </template>

                                </td>
                                
                            </template>
                        </template>
                </tr>
            </table>



          </div>  <!-- //END CARD BODY -->
        </div>
      </div>  <!-- //END CARD -->


      <div class="card" v-show="showLog && log">
        <div class="card-header alert-success  p-0"  id="heading5">
          <b class="mb-0">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                <b>5.Zápis změn</b>
            </button>
          </b>
        </div>
        <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
          <div class="card-body">

                <div class="alert alert-success border" v-show="showLog">

                        <div class="row alert-info"> 
                            <div class="col-5">
                                Název
                            </div>
                            <!--
                            <div class="col-2">
                                Cena
                            </div>
                            <div class="col-2">
                                Sleva
                            </div>
                            <div class="col-2">
                                Bonus
                            </div>
                            -->
                            <div class="col-7">
                                Status
                            </div>
                            
                        </div>
                        <div class="row" v-for="l in log"> 
                            <div class="col-5">
                                <template v-if="l.car_id>0">
                                    <a :href="'/cars' +'/' +l.car_id">
                                        {{ l.title}}
                                    </a>
                                </template>
                                <template v-else>
                                {{ l.title>""?l.title: l.id}}
                                </template>


                            </div>
                            <!--
                            <div class="col-2">
                                {{ l.price }}
                            </div>
                            <div class="col-2">
                                {{ l.discount }}
                            </div>
                            <div class="col-2">
                                {{ l.bonus }}
                            </div>
                            -->
                                <!--    if ($l['status']==100) {$success++;} else {$errors++;} -->                  
                            <div class="col-7" v-html="l.status_message">                           
                            </div>
                            
                        </div>
                    <center><a href="#" @click.prevent="showLog=false" class="btn btn-sm btn-primary">Pokračovat</a> <a href="/administrace" class="btn btn-sm btn-secondary">Návrat do administrace</a></center>
                </div>
                        
          </div>
        </div>
      </div>



</div> <!-- ACCORDITION -->







<!-- VUE TABLE START -->
<div class="table-responsive"> 

            <table class="simple-table" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th width="50"></th>
                  <th width="10%" class="text-center">SPZ / VIN</th>
                  <th width="15%" class="text-center">Vůz</th>
                <th title="Lokalita" width="5%" class="text-right" v-if="offs>1"  @click.prevent="orderBy('office_id')" :class="{'text-info':this.orderColumn=='office_id'}"> 
                    Lok
                    <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                    
                </th>
                  <th width="7%" @click.prevent="orderBy('dni_sklad')" :class="{'text-info':this.orderColumn=='dni_sklad'}" title="Počet dní na skladě od přivezení vozidla">
                    Na skladě
                    <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                  </th>

                  <th width="7%" class="text-right" @click.prevent="orderBy('dni_inzerce')" :class="{'text-info':this.orderColumn=='dni_inzerce'}">
                        Dní inzerce <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                  </th>

                  <th width="10%" class="text-right" @click.prevent="orderBy('car_margin')" :class="{'text-info':this.orderColumn=='car_margin'}">
                        Marže
                        <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                  </th>

                  <th width="10%" class="text-right" @click.prevent="orderBy('cost_price')" :class="{'text-info':this.orderColumn=='cost_price'}">
                        Pořizovací cena
                    <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>

                  </th>
                  <th width="10%" class="text-right"  :class="{'text-info':this.orderColumn=='car_price'}">
                        <i title="Editovat tuto položku" class="fa fa-check-square"  :class="{'fa-check-square':type==0,'fa-square':type!=0}" aria-hidden="true"  @click.prevent="type=0"></i>
                        <span @click.prevent="orderBy('car_price')" >Prodejní cena
                        <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                        </span>
                  </th>
                  <th width="10%" class="text-right"  :class="{'text-info':this.orderColumn=='car_discount'}">
                        <i class="fa" aria-hidden="true" :class="{'fa-check-square':type==1,'fa-square':type!=1}" @click.prevent="type=1" title="Editovat tuto položku"></i>
                    <span @click.prevent="orderBy('car_discount')">Akční sleva
                        <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true" ></i>
                    </span>
                  </th>

                  <th width="10%" class="text-right" :class="{'text-info':this.orderColumn=='car_bonus'}">
                        <i title="Editovat tuto položku" class="fa" :class="{'fa-check-square':type==2,'fa-square':type!=2}"  aria-hidden="true" @click.prevent="type=2"></i>
                        <span @click.prevent="orderBy('car_bonus')">Akční bonus
                        <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                        </span>
                  </th>

                  <th width="15%">Změna<br>&nbsp;</th>
                  <th width="20" title="Poznámky ke změnám"><i class="fas fa-info-circle"></i><br>&nbsp;</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(car, index) in cars" :key="car.id"  v-if="car.show_it" :class="{'bg1':car.selected}">
                        <td>
                            <center>
                                <input type= "checkbox"  name="car_checkbox" @change="selected(car.id)"  :v-model="car.selected" :checked="car.selected">
                            </center>
                        </td>

                        <td>
                            <nobr>
                                <center>
                                    <nobr>{{ formatSpz(car.spz) }}</nobr>
                                </center>
                                <small>{{ car.vin }}</small>
                            </nobr>
                        </td>

                        <td> 
                            <a :href="'/cars/'+car.id" target="_new">
                            {{ car.title }}
                            </a>
                        </td>
                    
                        <td v-if="offs>1"> 
                            {{ car.office_abbr }}
                        </td>
                    
                        <td> 
                            {{ car.dni_sklad }}
                        </td>
                    
                        <td> 
                            {{ car.dni_inzerce }}
                        </td>
                        <td> 
                            {{ formatCurrency(car.car_margin) }}
                        </td>
                        <td> 
                            {{ formatCurrency(car.cost_price) }}
                        </td>
                    
                        <td> 
                                {{ formatCurrency(car.car_price) }}
                            <div v-show="type==0 && car.selected">
                                <input type="number" model="car.car_price_change" @change="updateField($event, 1, car.id)" number>
                            </div>
                            <div v-if="car.price_suggestion>0" class="w-100 badge badge-success" :title="'Navrhl ' + car.firstname_suggestion + ' ' + car.user_suggestion + ' dne ' + formatDate (car.date_suggestion) + '. Návrh bude automaticky smazán po změně ceny.'" @click="acceptSuggestion(car.id)" style="cursor: alias;">
                                 {{ car.firstname_suggestion + ' ' + car.user_suggestion }}:
                                {{ formatCurrency(car.price_suggestion) }}Kč
                            </div>
                        </td>
                    
                        <td> 
                            {{ formatCurrency(car.car_discount) }}
                            <div v-if="type==1 && car.selected">
                                <input type="number" model="car.car_discount_change" @change="updateField($event, 2, car.id)" number>
                            </div>
                        </td>
                    
                        <td> 
                            {{ car.car_bonus }}
                            <div v-if="type==2 && car.selected">
                                <input type="number" model="car.car_bonus_change" @change="updateField($event, 3, car.id)" number>
                            </div>
                        </td>

                        <!-- CHANGES -->
                        <td> 
                            <div v-show="car.car_price_change!==null&&car.selected">
                                <!-- car.car_price<>car.car_price_change -->
                                <nobr>
                                    cena&nbsp; {{ car.car_price_change }}
                                    <i :class="{'fas fa-chevron-circle-up text-success':car.car_price_change>car.car_price, 'fas fa-chevron-circle-down text-danger':car.car_price_change<car.car_price}" aria-hidden="true"></i>
                                </nobr>
                            </div>
                            <div v-show="car.car_bonus_change!==null&&car.selected">
                                <!-- car.car_price<>car.car_price_change -->
                                <nobr>
                                    bonus {{ car.car_bonus_change }}
                                    <i :class="{'fas fa-chevron-circle-down text-success':car.car_bonus_change<car.car_bonus, 'fas fa-chevron-circle-up text-danger':car.car_bonus_change>car.car_bonus}" aria-hidden="true"></i>
                                </nobr> 
                            </div>
                            <div v-show="car.car_discount_change!==null&&car.selected">
                                <!-- car.car_price<>car.car_price_change -->
                                <nobr>
                                    sleva {{ car.car_discount_change }}
                                    <i :class="{'fas fa-chevron-circle-down text-success':car.car_discount_change<car.car_discount, 'fas fa-chevron-circle-up text-danger':car.car_discount_change>car.car_discount}" aria-hidden="true"></i>

                                </nobr>
                            </div>
                        </td>
                        <td>

                            <i class="fas fa-info-circle text-info" :title="car.price_history" v-if="car.price_history>''"></i>                            
                        </td>
                </tr>
              </tbody>
          </table>

</div><!-- VUE TABLE START -->



    </div>
</template>



<script>
    export default {

      props: {
        action:String,
        carset:Array, 
        resultlog:Object, 
        allowedoffices:Array,

       },
      data: function() {
        return {

            localStorageVars:[  //co všechno uložit do local storage
                'cars',
                'lsSaved',
                'statType', 
                'autoSave',
                'type',
                'average',
                'difference',
                'asc',
                'orderColumn',
                'orderDir',
                'showSelectedOnly',
                'select',
                'numericFilterType',
                'changeReferenceType',
                'changeValue',
            ],
            lsSaved:false,
            autoSave: false, //zda uatomaticky ukládat
            saving:false, //zda ukládá
            type: 0, //co měnit
            loading:true,
            types:[
                {id:0, title: "prodejní cena", column:"car_price", plusOnly:false},
                {id:1, title: "sleva z ceny", column:"car_discount", plusOnly:true},
                {id:2, title: "bonus prodejce", column:"car_bonus", plusOnly:true},
            ],

            changeJSON:"",  //všechny změny k odeslání na server

            numericFilter: 1, //co měnit
            numericValue:0,
            numericAddOnly:0,
            numericRemoveOnly:1,
            numericFilters:[
                {id:0, title: "pořizovací cena", column:"car_price"},
                {id:1, title: "doba na skladě", column:"dni_sklad"},
                {id:2, title: "akční sleva", column:"car_discount"},
                {id:3, title: "marže", column:"car_margin"},
            ],


            //statistika
            stat:{}, //0 = aktuální stav, 1 = změny
            pivots:[], //seznam všech pivotů
            statType:0, 
            statFuns:[  //projede všechny funkce jak pro navržené změny tak pro aktuální stav
                {id:'count', noChange:true,             title:'Počet',      
                    fun:function(car, change) { return change?0:1;}
                }, //fun: vrádí hodnotu co se má připočítat , pokud change=true
                {id:'cost_price_sum', noChange:true,    title:'Pořizovací cena',    fun:function(car, change) { return change?0:car['cost_price'];}
                }, //fun: vrádí hodnotu co se má připočítat

                {id:'margin_sum', noChange:false,   title:'Marže',  fun:function(car, change) { 
                    return change?(car['car_price_change']-(car['car_price_change']!==null?car['car_price']:0)-(car['car_discount_change']-(car['car_discount_change']!==null?car['car_discount']:0))):car['car_margin'];}
                }, //fun: vrádí hodnotu co se má připočítat



                {id:'price_sum', noChange:false,        title:'Prodejní cena',  
                    fun:function(car, change) { 
                        return change?(car['car_price_change']-(car['car_price_change']!==null?car['car_price']:0)):car['car_price'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat

                {id:'discount_sum', noChange:false,     title:'Sleva',  
                    fun:function(car, change) { 
                        return change?(car['car_discount_change']-(car['car_discount_change']!==null?car['car_discount']:0)):car['car_discount'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat

                {
                    id:'bonus_sum', noChange:false,         title:'Bonusy',     
                    fun:function(car, change) { 
                        //změna znamená rozdíl oproti původnímu

                        return change?(car['car_bonus_change']-(car['car_bonus_change']!==null?car['car_bonus']:0)):car['car_bonus'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat


            ],

            statPivots:[
                {   
                    id:0, 
                    title: "Původ", 
                    pivotColumn:"origin_group", 
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"origin_group",  //umožní mít jiný pivot než title
                }, 

                {   
                    id:1, 
                    title: "Model", 
                    pivotColumn:"model_title", 
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"model_title",  //umožní mít jiný pivot než title
                }, 

                {   
                    id:2, 
                    title: "Lokalita", 
                    pivotColumn:"office", 
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"office",  //umožní mít jiný pivot než title
                }, 

            ],
            countColumn:'count', //sloupec s průměrnou cenou ze stat funs
            average:false, // zda je přepnuto na průměrnou cenu
            difference:false, // zda je přepnuto na průměrnou cenu

            show:false,

            asc:true, //pořadí řazení tabulky
            orderColumn:'',
            orderDir: false,
            cars:[], //vozidla
            log:[], //chyby po uložení
            offices:[], //seznam povolených kanceláří
            showSelectedOnly:false,
            select:1,   //označ = 1 či odznač = 0
            numericFilterType:1,
            changeReferenceType:0,
            changeValue:0,
            showLog:true,
            changeReference:[
                { id:0, title: "Kč", column: "", fun: (val, car)=>{ return val;}},
                { id:1, title: "% z pořizovací ceny", column: "cost_price", fun: (val, car)=>{ return Math.round(car.cost_price*val/100);}},
                { id:2, title: "% z prodejní ceny", column: "car_price", fun: (val, car)=>{ return Math.round(car.car_price*val/100);}},
                { id:3, title: "% z marže", column: "car_margin", fun: function(val, car){ return Math.round(car.car_margin*val/100);}},
                { id:4, title: "% z bonusu", column: "car_bonus", fun: (val, car)=>{ return Math.round(car.car_bonus*val/100);}},
                { id:5, title: "% ze slevy", column: "car_discount", fun: (val, car)=>{ return Math.round(car.car_discount*val/100);}},
            ],

        }
      },

      computed: {
        selectedCount: function() {
            var count = 0;
            for(var i = 0; i < this.cars.length; ++i){
                if(this.cars[i].selected)
                    count++;
            }       
            return count;           
        },
        typeTitle: function(){
            return this.types[this.type].title;
        },
        typeColumn: function(){
            return this.types[this.type].column;
        },

        statTitle: function(){
            return this.statPivots[this.statType].title;
        },

        statColumn: function(){
            return this.statPivots[this.statType].pivotColumn;
        },


        statPivotFun: function(){
            return this.statPivots[this.statType].pivotId;
        },


        plusOnly: function(){
            return this.types[this.type].plusOnly;  
        },

        numericFilterTitle: function(){
            return this.numericFilters[this.numericFilter].title;   
        },
        numericFilterColumn: function(){
            return this.numericFilters[this.numericFilter].column;  
        },

        changeColumn: function(){
            return this.changeReference[this.changeReferenceType].column;   
        },
        changeFunction: function(){
            return this.changeReference[this.changeReferenceType].fun;  
        },

        changesCount: function(){
            var count = 0;
            this.cars.forEach((car)=>{
                if (car.selected) {
                    if (car.car_bonus_change!==null) {count++;
                        console.log(car.id + ' BONUS');}
                    if (car.car_discount_change!==null) {count++;console.log(car.id + ' DISC');}
                    if (car.car_price_change!==null) {count++;console.log(car.id +  ' PRICE');}
                }
            });   
            return count;
        },
        offs:function(){
            if (this.offices) {
                return this.offices.length;
            } else {
                return 0;
            }
        },


      },
      methods: {

        bind:function() {
            this.saving = false;
            // $(window).bind('beforeunload',this.leaving);


            // $(window).bind('beforeunload', function(){
            //   return 'Opravdu chcete opustit tento formulář?';
            // });
        },


        unbind: function () {
            //console.log('UNBIND');
            this.saving = true;
            //$(window).unbind('beforeunload');
        },

        leaving: function () { //když opouští stránku
            // console.log('leaving...');
            // console.log(this.autoSave);
            if (this.autoSave || this.saving) { return true;}
            // this.saveToLocalStorage(); //uloží rozpracovanou práci
            return 'Máte uložené změny?';
        },

        restoreFromLocalStorage: function(){ //obnoví všechny vlasnosti do objektu

            //načti
            this.localStorageRestore(localStorageVars,'ls_');

            //přepočítat stat
            this.calcStat();
        },

        saveIf: function(){
            if (this.autoSave) {
                this.saveToLocalStorage();
            }
        },

        restoreIf: function(){
            if (localStorage.ls_autoSave && JSON.parse(localStorage.ls_autoSave)) {
                this.restoreFromLocalStorage();
            }
        },

        saveToLocalStorage: function(){
            this.lsSaved = true;
            this.unbind();

            //načti
            this.localStorageSave(localStorageVars,'ls_');
        },

        switchSelect: function() {
            if (!this.select) {
                this.select = 1;
            } else {
                this.select = 0;
            }
        },
        //seřadí celý set
        orderBy:function(column){
            // alert('ORDER '+column);
            this.orderColumn = column;
            this.orderDir = this.asc;
            this.asc = !this.asc;
            this.cars.sort((a,b)=>(this.asc?1:-1)*((+a[column]>+b[column])?1:-1));
            
        },
        selectNumeric:function(lower){
            var col = this.numericFilterColumn;
            var val = this.numericValue*1;
            this.cars.forEach((car)=>{

                //nezabývat se s položkami, které nejsou vybrané pokud pracovat jen nad výběrem
                if (!this.showSelectedOnly || car.selected==1) {                
                    if (
                        (lower && (car[col]*1<=val)) 
                        || 
                        (!lower && (car[col]*1>=val) )
                        ) {
                        //vyhovuje podmínce
                            car.selected = this.select?1:0;  //označ nebo odznač

                    } else {
                        //nevyhovuje podmínce
                            car.selected = this.select?0:1; //označ nebo odznač
                    }
                }

                    // if (this.numericRemoveOnly!=1) {
                    //  car.selected = 1;
                    // }
                    // if (this.numericAddOnly!=1) {
                        // car.selected = 0;    
                    // }
                    // if (this.numericRemoveOnly==1) {
                    //  car.selected = 0;   
                    // }
            });         
        },
        calculate: function(){
            //jakou položku
            var col = this.typeColumn;
            var val = this.changeValue;
            var fun = this.changeFunction;
            var plusOnly = this.changePlusOnly;
            this.cars.forEach((car)=>{
                if (car.selected) {
                    // console.log(fun(val,car) + '=> '+col + '_change');
                    var r = +fun(val,car);
                    //toto je hodnota o kterou se má referenční sloupec (TYPE) změnit !!! přičíst
                    //pouze kladné hodnoty
                    // if (!this.plusOnly || r>0) {
                        car[col + '_change'] = +car[col] + r;
                    // }
                }
            })
            this.calcStat();
            this.changeJSON = this.transformChanges();

        },
        showLine: function (carId) {
            return true;
        },

        emptyStat: function() { //resetuje statistiku v objektu
            var s={};
            this.statFuns.forEach((fun,index)=>{
                s[fun.id] = 0;
            });
            return s;
        },
        resetStat:function(){ //resetuje počítadla před počítáním (calcStat)
            //součty
            for (var i = 0; i < 2; i++) {
                this.stat[i] = this.emptyStat();
            }
            this.pivots = {};
        },
        calcStat:function (type) { //napočítá hodnoty pro selected - 0 = regulerní, 1 = změna
            var col = this.statColumn; //sloupec, kolem kterého se pivotuje
            var pivotIdFun = this.statPivotFun;
            // statType
            this.resetStat();
            this.cars.forEach((car)=>{
                if (car.selected) {
                        this.statFuns.forEach((func,index)=>{
                            var val = Math.round(func.fun(car, false),0);
                            var valChange = Math.round(func.fun(car, true),0);
                            //total sums
                            this.stat[0][func.id] = this.stat[0][func.id] + val;
                            this.stat[1][func.id] = this.stat[1][func.id] + valChange;
                            //pivot sums
                            var pivotValue = pivotIdFun(car[col]); //hodnota pivotu
                            //pokud neexistuje - resetuj
                            if (typeof this.stat[0][pivotValue] === 'undefined') {
                              this.stat[0][pivotValue] = this.emptyStat();
                              this.stat[1][pivotValue] = this.emptyStat();
                              this.pivots[pivotValue] = car[col];
                            }                           
                              this.stat[0][pivotValue][func.id] = this.stat[0][pivotValue][func.id] + val;
                              this.stat[1][pivotValue][func.id] = this.stat[1][pivotValue][func.id] + valChange;
                        });
                }
            });
            //seřazení pivota
            const ordered = {};
            Object.keys(this.pivots).sort().forEach((key)=>{
              ordered[key] = this.pivots[key];
            });         
            this.pivots = ordered;
            this.saveIf();
            // console.log(this.pivots);
        },


        //připraví výsledky
        transformChanges:function() {
            var result = [];
            this.cars.forEach((car)=>{
                if (car.selected && ((car.car_bonus_change!=0) || (car.car_discount_change!=0) || (car.car_price_change!=0))) {
                    result.push({'id':car.id, 'bonus':car.car_bonus_change, 'price':car.car_price_change,'discount':car.car_discount_change});
                }
            });
                //serializace
                result = JSON.stringify(result);
            return result;
        },

        sendChanges:function() {  //odesle do databaze zmenový požadavek...
            //naplní pole k odeslání
            if (confirm('Navrženo změn ' + this.changesCount + '. Opravdu všechny uložit do databáze?')) {

                this.unbind();
                this.saving = true;
                this.changeJSON = this.transformChanges();
    
                this.reset();
                this.saveIf();
            }
            // console.log(this.changeJSON);
            // alert(this.changeJSON);
        },

        //zruši všechny změny
        reset: function() {
                this.cars.forEach((car)=>{
                    if (car.selected && ((car.car_bonus_change!=0) || (car.car_discount_change!=0) || (car.car_price_change!=0))) {
                        car.car_bonus_change=null;
                        car.car_discount_change=null;
                        car.car_price_change=null;
                    }
                });            
        },
        resetChanges:function() {
            if (confirm('Navrženo změn ' + this.changesCount + '. Opravdu je chcete všechny zrušit?')) {
                this.reset();
            }
        },

        selected: function (carId) {
            this.cars.forEach((car, id)=>{
                if (car.id == carId) {
                    if (car.selected) {
                        car.selected=0;
                    } else {
                        car.selected=1;
                    }
                }
            });
        },

        updateField: function (event, type, carId) {
            this.cars.forEach((car, id)=>{
                if (car.id == carId) {
                    // console.log(this.typeColumn + '_change');
                    car[this.typeColumn + '_change'] = +event.target.value;
                    this.calcStat();
                    this.changeJSON = this.transformChanges();
                }
            });
        },

        toggleType: function () {

            if (this.type>=2) {
                this.type = 0;
            } else {
                this.type ++;
            }
        },
        selectAllVue: function(){
            this.cars.forEach(car=>{
                car.selected = 1;
            });
            this.switchSelect();
        },

        selectNoneVue: function(){
            this.cars.forEach(car=>{
                car.selected = 0;
            });
            this.switchSelect();
        },

        invertSelect: function(){
            this.cars.forEach(car=>{
                car.selected = (car.selected==1)?0:1;
            });
        },

        formatCurrency: function (number) {
            number = Math.floor(number);
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
        },


        formatDate: function (date) {


            var date = new Date (date);
              var day = date.getDay();
              var month = date.getMonth();
              var year = date.getFullYear();

            return day + '.' + month + '.' + year;
        },

        acceptSuggestion: function (id) {
            if (id>0) {
                //najdi ID 
                this.cars.forEach((car)=>{
                    if (car.id === id) {
                        car.car_price_change=car.price_suggestion;
                    }
                });    
                //přidej car.price_suggestion do 
            }

        },


      }, // METHODS


      created:function(){
        this.cars = this.carset; //vozidla
        this.log = this.resultlog; //výsledek uložení
        this.offices = this.allowedoffices;  

        this.calcStat();

        if (localStorage.ls_autoSave && JSON.parse(localStorage.ls_lsSaved)) {
            this.lsSaved = true;
        }
      }, //CREATED

      mounted:function(){
        this.loading = false;
        this.restoreIf();
      }, //MOUNTED

      watch:{
        selectedCount: function() {
            this.calcStat();
        },

        changesCount: function() {
            if (this.selectedCount>0) {this.bind();} else {this.unbind();}
        },

        statType:function() {
            this.calcStat();
        },

        autoSave: function() {
            //uloží aby se tím i nadále řídil
            localStorage.setItem('ls_autoSave', this.autoSave);
        },

        showSelectedOnly:function(){
            this.cars.forEach(
                (item)=>{
                    if (!this.showSelectedOnly || this.showSelectedOnly&&(item.selected==1)) {
                        item.show_it =  1;
                    } else {
                        item.show_it =  0;
                    }
                }
                );
        },

      }, //WATCH


    }
</script>
<style>

</style>
