<template>
    <div class="c-cars-container">
                <div class="c-cars-header"  v-if="cars && cars.length>1">
                    <div>
                        <span v-for="o in orderAll" class="btn-new" :class="{ 'btn-primary':o.id==order,'btn-secondary':o.id!=order}" @click="orderBy(o.id)" :key="o.id">
                        {{ o.title }}
                        </span>
                        <span class="btn-new" @click="orderAscendent(true)" :class="{'btn-primary':orderAsc}" v-if="order>0">
                        <i class="fas fa-chevron-circle-up"></i>
                        </span>
                        <span class="btn-new" @click="orderAscendent(false)" :class="{'btn-primary':!orderAsc}" v-if="order>0">
                            <i class="fas fa-chevron-circle-down"></i>
                        </span>
                    </div>
                </div>

        <div class="row">
            <div class="col" v-if="!cars || cars.length==0">
                <div class="c-btn-category mt-2 mb-3 p-3">
                    <center v-if="!showWaiting">
                        <h3>
                            Nenalezena žádná vozidla. 
                        </h3>
                        <div v-if="waiting==6" class="alert alert-info">
                            <i class="far fa-check-circle"></i> Hlídání již bylo nastaveno.
                        </div>
                        <div v-else class="alert alert-warning">
                            <i class="fas fa-info-circle"></i>&nbsp;Můžete nastavit Hlídání. Je potřeba vyplnit kontaktní údaje zákazníka, zvolit parametry vozu a provést hledání tlačítkem "Načíst vozidla". Přitom nesmí být nalezeno žádné vozidlo.
                        </div>
                    </center>
                    <div v-else>
                        <center>
                        <h3>Hlídání vozidla s parametry</h3>
                        </center>
                        <div><b>Nenalezena žádná vozidla. </b>Aplikace bude pravidelně kontrolovat databázi skladových vozidel a najde-li vozidlo odpovídající parametrům, založí alert, příp. odešle notifikaci prodejci. Vyhledávání je prováděno nad databází se stejným nastavením, jako jse naposledy vyhledávali (tj. i zda kontrolovat jen aktuální salon nebo všechny a zda se řídit typem případu).</div>

                            <div class="btn-new" @click="startWaiting">
                                <i class="fas fa-history icon-size"></i>Zapnout hlídání
                            </div>                        
                            <span v-for="text in selectedButtons" :key="text" class="badge badge-warning mx-1">
                                    {{ text}}
                            </span>
                        </div>
                </div>
            </div>
            <div v-else class="col-xl-6"  v-for="car in cars" :key="car.id">
                <div class="c-cars" :class="{'c-cars-active':activeCar==car.id, 'c-cars-selected':isSelected(car.id)}"  @mouseover="activeCar=car.id" @click="activeCar=car.id">
                    <div class="c-cars-img">
                        <div style="font-size:.8rem;font-weight:700; position: relative;top:0px; width:200px; text-align:center; background-color:transparent; color:rgb(128, 0, 0);">
                            <i class="fas fa-map-marker-alt"></i> {{ car.office }}
                        </div>
                        <img :src="car.img_title" width="200">
                    </div>
                    <div @click="showCar(car.id)" class="c-cars-content py-1">
                        <h3>{{ car.title }}</h3>
                        <div class="row">
                            <div class="col-md-3">Cena</div>
                            <div class="col-md-9">{{  formatCurrency(car.price) }} Kč</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">VIN</div>
                            <div class="col-md-9">{{  car.vin }}</div>
                        </div>

                        <div class="row">
                            <div class="col-md-3">Nájezd</div>
                            <div class="col-md-9">{{  car.odo }} km</div>
                        </div>

                        <div class="row">
                            <div class="col-md-3">Registrace</div>
                            <div class="col-md-9">{{  car.month }}/{{ car.year }}</div>
                        </div>


                    </div>
                    <div @click="selectCar(car.id)"><i class="far c-select-btn" :class="{'fa-square':!isSelected(car.id), 'fa-check-square':isSelected(car.id)}"></i></div>

                </div>
            </div>

        </div>

    </div>
</template>



<script>
    export default {

      props: {
          cars:Array,
          order:0,
          orderAsc:0,
          orderAll:{type:Array},
          selectedButtons:Array,
          demandType:0,
          isActive:Boolean,
          waiting:0,
       },
      data: function() {
          return {
            activeCar:1,
            change:0,
        }
      },

        computed: {
            selectedCars: function() {
                return this.$store.getters.getSelectedCars;
            },
            showWaiting: function(){
                return (this.waiting===99);
            }
        },

        mounted: function() {
            //nastaví první kategorii jako aktivní
            if (this.cars && this.cars.length>0) {
                this.activeCar = this.cars[0].id;
            }
            this.change = 0;
            if (this.selectedButtons) {this.change = this.selectedButtons.length};
        },

        methods: {
            startWaiting: function() {
                this.$emit('wait-for');
            },
            orderBy:function(id){
                if ((id!=this.order)) {
                    this.$emit('order-by',id);
                }
            },
            orderAscendent:function(asc){
                console.log('ASC');
                if ((asc&&this.orderAsc!=1) || (!asc&&this.orderAsc==1)) {
                    this.$emit('order-asc',asc?1:0);
                }
            },
            loadOffer:function () {

                this.$emit('load-offer');
                this.change = 0;
            },
            showCar:function(id){
                this.$emit('show-cars', id);
            },
            selectCar:function(id){
                //pokud je v selected cars, pak jej vyjme, jinak přidá
                this.$emit('car-selected',id);
            },
            //zda je tlačítko vybráno
            isSelected: function(id) {
              return  this.selectedCars.indexOf(id)!==-1;
            },

            toggleBtn: function(id){
                //pokud není v seznamu - přidej ho tam, jinak ho odeber
                let i = this.selectedButtons.indexOf(id);
                if(i===-1) {
                    console.log('ADD');
                    this.selectedButtons.push(id);
                } else {
                    console.log('found');
                    this.selectedButtons.splice(i,1)
                }
                this.change=1;

                this.$emit('button-change', this.selectedButtons);
            },

        },
        

    }
</script>


<style>
    .c-cars-header{
        display: flex;
        justify-content: space-between;
    }

    .c-cars-img{
        min-width: 200px;
    }


    .c-cars-content{
        flex-grow:1;        
    }

    .c-cars-content h3{
        font-size: 1.1rem;
        font-weight: 600;
    }




    .c-cars-btext{
        font-weight: 500;
        padding-left: 2rem;
    }
    .c-cars {
        background-color:white;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin:5px;
        border:silver 1px solid;
        box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    }

    .c-cars-active{
        background-color: rgb(235, 232, 232);
        transition: .4s;
    }
    .c-cars-selected {
        background-color: rgb(218, 218, 218);
        /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
        box-shadow:0;
        border:1px solid blue;
        transition: all 1s;
    }
    .c-select-btn {
        font-size: 1.5rem;
        color: blue;
        margin: 1rem;
    }

    .c-select-btn .c-cars-selected {
        color: green;

    }

</style>
