<template>    
    <div class="form-group">

        <slot name='prepend'></slot>
        <div class="custom-control" :class="{'custom-switch':type=='switch', 'is-invalid':this.invalid||this.mystery}" >            

        <div class="input-group">

            <template v-if="type=='switch'||type=='checkbox'">
                    <input type="checkbox" class="custom-control-input" :id="id"
                    :checked="isCheckedCheckbox" 
                    :value="value" 
                    @change="updateInputCheckbox"
                    :class="classObject"

                    :disabled="disabled"
                    >
                    <label class="custom-control-label" :for="id">{{label}}</label>
            </template>

            <template v-if="type=='radio'">
                        <input 
                            class="form-check-input" 
                            :class="classObject"
                            type="radio" 
                            :name="name" 
                            :id="id" 
                            :value="value"
                            :checked="isCheckedCheckbox"
                            @change="updateInputCheckbox"
                        >
                        <label class="form-check-label" :for="id">
                            {{label}}
                        </label>
            </template>


            <template v-if="type=='select'">
               <div class="input-group">
                    <div class="input-group-prepend" v-if="label>''">
                        <label class="input-group-text" :for="id">{{ label }}</label>
                        <select
                                class="custom-select" 
                                :class="classObject"
                                :name="name" 
                                :id="id" 
                                :value="value"
                                @change="updateInput"
                        >
                            <option v-for="(lab, val) of items" :value="val" :key="val" :selected="val==modelValue">{{ lab }}</option>
                        </select>
                    </div>                
                </div>

            </template>

            <template v-if="type=='email'||type=='text'||type=='number'">
                        <!-- <label :for="id" class="col-form-label-sm" v-if="label>''">{{ label }}</label> -->
                        <div class="input-group-prepend" v-if="label>''">
                            <label class="input-group-text" :for="id">{{ label }}</label>
                        </div>                        
                        <input 
                            :type="this.type"
                            class="form-control" 
                            :class="classObject"
                            :id="id" 
                            :placeholder="placeholder"
                            :disabled = "disabled"
                            v-bind:value="modelValue"
                            @change="updateInput"
                            @input="updateInput"
                        >
                        <slot name='append'></slot>
            </template>


            <template v-if="type=='textarea'">
                <div class="input-group-prepend" v-if="label">
                    <span class="input-group-text">{{ label }}</span>
                </div>
                <textarea 
                    class="form-control" 
                    :aria-label=label
                    :class="classObject"
                    :id="id" 
                    :placeholder="placeholder"
                    v-bind:value="modelValue"
                    @change="updateInput"
                    @input="updateInput"

                    ></textarea>
                    
            </template>



            <div class="invalid-feedback">
                <span v-if="invalid">
                    {{ invalidMessage }}
                </span>
                <span v-if="mystery">
                    {{ mysteryMessage }}
                </span>
            </div>
        </div>
        </div>

    </div>
</template>



<script>
/**
 * number
 * string
 * checkbox
 * switch
 * select
 */
    export default {
        model: {
            prop: 'modelValue',
            event: 'change'
        },
      props: {
          name:"",
          id:"",
          value:null,
          label:{ type: String},
          placeholder:{ type: String},
          modelValue:{ default: "" },
          type:"",
          items:null,
          response:[],
          mystery:{ default:false},
          mysteryMessage:"", //pokud bude mystery true, pak zobrazí i tuto zprávu
          checked:"",
          invalid:{ default:false},
          disabled:{ default:false}, //zda je prvek neaktivní

          disabled:"",
          invalidMessage:{type:String, default:"Chyba"},
          customClass:{},
          autofocus:"",

            trueValue: { default: 1 },
            falseValue: { default: 0 }
        
       },
      data: function() {
          return {
              checkbox_value:null,
            //   hasFocus:false,
        }
      },
        computed: {
            // focus(){
            //     return this.modelValue=="" || this.hasFocus;
            // },
            isCheckedCheckbox() {
            if (this.modelValue instanceof Array) {
                return this.modelValue.includes(this.value)
            }
            // Note that `true-value` and `false-value` are camelCase in the JS
            return this.modelValue == this.trueValue
            },      
            classObject() {
                return {
                    'is-invalid':this.invalid||this.mystery,
                }
            }
        },
        methods: {
            updateInputCheckbox(event) {
                let isChecked = event.target.checked
                if (this.modelValue instanceof Array) {
                    let newValue = [...this.modelValue]
                    if (isChecked) {
                    newValue.push(this.value)
                    } else {
                    newValue.splice(newValue.indexOf(this.value), 1)
                    }
                    this.$emit('change', newValue)
                } else {
                    if (this.type==="radio") {
                        this.$emit('change', this.value)
                        } else {
                        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
                    }
                }
            },

            updateInput(event) {
                        this.hasFocus = true;
                        //this.$emit('input', event.target.value)
                        this.$emit('change', event.target.value)
            },


        },

    mounted:function() {
        this.checkbox_value = this.value;
    },

    }
</script>


<style>


</style>
