<template>
    <div keydown="listen" class="demand-container ml-lg-3 mr-lg-3 ">
        <div class="loading" v-if="$store.getters.loading">
            <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
            </div>
        </div>

        <demand-mystery-component 
            :customer-data="customer" 
            :contact-type="demand_contact_type"
            :demand-type="demand_type"
            :demand-id="demand_id"
            :demand-phase="demand_phase"            
            :demand-test-drive="demand_test_drive"
            :demand-invitation="demand_invitation"
            :demand-financing="demand_financing"
            :demand-comission="demand_comission"
            :demand-description="demand_description"
            @go-to="goTo"
        >
        </demand-mystery-component>



        <!-- TITLEPANEL ************************************************************************* -->

            <a name="tab0"></a>
        <div class="title-panel">
            <div class="float-right">
                            <div class="btn-new">
                            <a href="/poptavky">                          
                                <i class="fas fa-list icon-size"></i>Poptávky
                            </a>
                            </div>
                        <div class="btn-new" @click="saveDemand(999)" v-if="canEdit">
                        <i class="far fa-save icon-size"></i>Uložit poptávku
                        </div>
                        <div class="btn-new" @click="newDemand">
                        <i class="far fa-plus-square icon-size"></i>Nová poptávka
                        </div>

            </div>
                <!-- 
                <div class="mb-3" v-show="demand_contact_type>0" style="font-size:1.3rem;color:blue;" @click="demand_contact_type=null">
                    <div v-if="demand_contact_type==1"><i class="fas fa-eye"></i>    Osobní návštěva</div>
                    <div v-if="demand_contact_type==2"><i class="fas fa-phone"></i> Telefonický kontakt</div>
                    <div v-if="demand_contact_type==3"><i class="fas fa-envelope"></i> Emailová zpráva</div>
                </div> -->

            <div class="mb-3 d-inline-block" >
                <div class="contact-type-box" :class="{'contact-type-alert':demand_contact_type == 0}">
                    <div class="contact-type" :class="{'contact-type-active':demand_contact_type==1}" @click="setContactType(1)" title="Osobní návštěva">
                        <i class="fas fa-eye"></i>
                    </div>

                    <div class="contact-type" :class="{'contact-type-active':demand_contact_type==2}" @click="setContactType(2)" title="Telefon">
                        <i class="fas fa-phone"></i>
                    </div>

                    <div class="contact-type" :class="{'contact-type-active':demand_contact_type==3}" @click="setContactType(3)" title="Email">
                        <i class="fas fa-envelope"></i>
                    </div>
                </div>
            </div>

            




            <div class="ml-md-4 mb-3 d-inline-block" >
                <div class="contact-type-box" :class="{'contact-type-alert':demand_type == 0}">

                    <div class="contact-type" :class="{'contact-type-active':demand_type==3}" @click="setDemandType(3)" title="Konfigurátor">
                        <i class="fas fa-user-cog"></i>  
                    </div>

                    <div class="contact-type" :class="{'contact-type-active':demand_type==2}" @click="setDemandType(2)" title="Nový vůz - sklad">
                        NV
                    </div>

                    <div class="contact-type" :class="{'contact-type-active':demand_type==1}" @click="setDemandType(1)" title="Ojetý vůz">
                        OV 
                    </div>

                    <!-- <div class="contact-type" :class="{'contact-type-active':demand_type==3}" @click="demand_type=3" title="Výkup">
                        <i class="fas fa-hand-holding-usd"></i>
                    </div>

                    <div class="contact-type" :class="{'contact-type-active':demand_type==4}" @click="demand_type=4" title="Servis a oprava">
                        <i class="fas fa-car-crash"></i>

                    </div> -->
                </div>
            </div>


            <div class="ml-md-4 mb-3 d-inline-block px-1" title="Hodnocení poptávky podle úrovně vyplněnosti údajů">
                <div class="contact-type-box" :class="{'contact-type-alert':this.rating<20}">
                    <div class="contact-type" :class="{'contact-type-active':this.rating>60}">

                            {{ this.rating }}<span style="font-size:1rem;">%</span>
                    </div>
                </div>
            </div>



        </div> <!-- titlepanel -->


        <!-- ERROR ************************************************************************* -->

        <div class="alert alert-danger mx-2" v-if="!canEdit">
            <i class="fas fa-exclamation-circle"></i> Tuto poptávku nemáte oprávnění upravovat.
        </div>

        <div v-if="errors">
            <div v-for="(error,key) in errors" class="alert alert-danger" :key="key">{{ error }}</div>
        </div>

        <!-- CUSTOMER ************************************************************************* -->



            <demand-customer-component 
                :customer-data="customer" 
                :load-customer="loadCustomer"
                :contacts = "contacts"
                @customer-update="customerUpdate"
                @customer-rating="calculateCustomerRating"
                
                :open="openTab(1)" 
                :is-active-tab="isActiveTab(1)" 
                @active-tab="activeTab=1" 
                @toggle-tab="toggleTab"
                @save-customer="saveCustomer"

            />


        <!-- CARS ************************************************************************* -->
        <demand-panel-component id="2" :open="openTab(2)" :active="isActiveTab(2)" @active-tab="activeTab=2" @toggle-tab="toggleTab" v-if="canEdit">

                <div slot="title">
                    <h2 class="d-inline-block"><i class="fas fa-car m-2"></i>
                    Vozidla
                    </h2>

                </div> 

                <div slot="title" v-if="loadedCars &&loadedCars.length>0" class="flex-fill text-center">
                    <span class="badge badge-light mx-2 mt-2 mb-1">
                    Nalezená vozidla: {{ loadedCars.length }} 
                    </span>
                </div>

                <div slot="title">
                    <demand-car-search
                        @selected-car="addCar"
                    >

                    </demand-car-search>
                </div>
                

                <div slot="content">
                    <div class="row">
                        <div class="col-md-6 px-3">

                            <input-component label="Vyhledá vozidla odpovídající typu případu" v-model="respect_demand_type" id="respect_demand_type" name="respect_demand_type" type="switch" value=1 @change="showSearchButton=true">
                            </input-component>                        
                        </div>
                        <div class="col-md-6 px-3">
                            <input-component label="Pouze v aktuálním autosalonu" v-model="respect_office" id="respect_office" name="respect_office" type="switch" value=1 @change="showSearchButton=true">
                            </input-component>                        
                        </div>



                    </div>

                    <demand-buttons-component 
                        :selected-buttons="selectedButtons" 
                        :categories="categories"
                        :show-button="buttonsChanged"
                        :demand-buttons="demand_buttons"
                        :carentryinit="carentry"
                        :demand-type="demand_type"
                        :admin = "admin"
                        @button-change="buttonChange"
                        @button-clear="buttonClear"
                        @toggle-tab="toggleTab"
                        @open-tab="openTab"
                        @load-offer="loadOfferButtons"
                        @updateCategory="updateCategory"
                    >
                    </demand-buttons-component> 

                    <demand-cars-component 
                        :cars="cars"
                        :order="order" 
                        :order-asc="order_asc" 
                        :order-all="orderAll" 
                        :demand-type="demand_type"
                        :is-active="activeTab===2"
                        :selected-buttons="demand_buttons"
                        :waiting = "showWaiting"
                        @car-selected="selectedCar" 
                        @order-by="orderBy"
                        @order-asc="orderAsc"
                        @show-cars="showFoundCars"
                        @wait-for="waitFor"
                    >
                    </demand-cars-component>
                </div>

        </demand-panel-component>


        <!-- DEMAND ************************************************************************* -->

        <demand-panel-component id="3" :open="openTab(3)" :active="isActiveTab(3)" @active-tab="activeTab=3" @toggle-tab="toggleTab">

                <div slot="title">                                
                    <h2 class="d-inline-block"><i class="fas fa-balance-scale m-2"></i>
                            {{ demand_title!==""?demand_title:'Poptávka'}} 
                        <span v-if="demand_phase">
                            {{ demand_phase['title']>""?(' - '+demand_phase['title']):'' }}
                        </span>
                    </h2>

                </div>                  
                <div class="flex-fill text-center" slot="title">

                    <div class="badge badge-light mx-2 mt-2 mb-1" title="Počet vybraných vozidel do nabídky" v-if="selectedCars && selectedCars.length>0">
                        V nabídce: 
                            <span v-if="selectedCars.length>1">
                                {{selectedCars.length}} vozidla
                            </span>
                            <span v-if="selectedCarsLoaded && selectedCars.length===1 && selectedCarsLoaded[0]">
                                {{selectedCarsLoaded[0].title}} 
                            </span>

                    </div>
              
                </div>
                <div slot="title">
                    <div class="badge badge-success mx-2 mt-2 pt-1 mb-1" title="ID poptávky" v-if="this.demand_id>0&&this.changes.length==0">
                        ULOŽENA
                    </div>

                    <div class="btn btn-sm btn-danger mx-2 mt-2 pt-1 mb-1" title="ID poptávky" v-if="(this.demand_id==null||this.changes.length>0)&&canEdit" @click.stop="saveDemand(999)">
                        <i class="far fa-save"></i> NEULOŽENA
                    </div>

                </div>

                <div slot="content">

                    <input-component label="Název poptávky" v-model="demand_title" id="demand_title" name="demand_title" type="text" @change="change('title')">
                    </input-component>

                    
                    <input-component label="Zákazník poptává" v-model="demand_description" id="demand_description" name="demand_description" type="textarea" @change="change('description')" placeholder="Detail požadavku zákazníka, omezení, informace k financování, výkupu vozidla, testovací jízdě apod.">
                    </input-component>

                    <div class="mb-3">
                        <div class="float-right" title="Zrušit všechna vybraná vozidla" @click="clearSelectedCars" v-if="canEdit && selectedCars.length>0">
                            <div class="btn-new">
                            <i class="far fa-trash-alt"></i> Smazat

                            </div>
                        </div>
                        <h3>Vozy v nabídce</h3>
                    </div>

                    <template v-if="demand_type==3">
                        <div class="col-md-4">
                            <input-component label="ID konfigurace" v-model="demand_config" id="demand_config" name="demand_config" type="text" @change="change('config')">
                            </input-component>
                        </div>                              
                        <div class="col-md-6">
                            <input-component label="Model vozidla" v-model="demand_config_title" id="demand_config_title" name="demand_config_title" type="text"  @change="change('config_title')">
                            </input-component>
                        </div>                              
<!-- 
                        <upload-component :type="8" :demand="demand_id" title="Načíst PDF z konfigurátoru (či jiný dokument)" v-if="demand_id>0">
                        </upload-component> -->

                    </template>

                    <template v-else>

                    <div v-if="(!selectedCarsLoaded || selectedCarsLoaded.length==0)">
                        <div class="alert alert-warning">
                            Nenalezena žádná vozidla.
                        </div>
                    </div>                        

                    <div class="c-selected-cars-container">
                        <div class="c-selected-cars" v-for="car in selectedCarsLoaded" :key="car.id">

                            <div class="row">


                                    <div class="col-md-1"  @click="showSelectedCar(car)">
                                        <div class="c-selected-cars-img">
                                            <img :src="car.img_title" width="120">
                                        </div>
                                    </div>
                                    <div @click="showSelectedCar(car.id)" class="col-md-5 c-selected-cars-content py-1">

                                        <strong>{{ car.title }}</strong>
                                        <input-component label="Přidat do případu" v-model="selectedCarsInclude[car.id]" :id="'car_' + car.id + '_add'" placeholder="" name="" type="switch" @change="includeCar(car.id, $event)" v-show="demand_phase==4">
                                        </input-component>
                                        <div class="row">
                                            <div class="col-md-3">Cena</div>
                                            <div class="col-md-9">{{  formatCurrency(car.price) }} Kč</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">VIN/SPZ</div>
                                            <div class="col-md-9">{{  car.vin }} {{  car.spz }}</div>
                                        </div>

                                    </div>
                                    <div class="col col-md-5">
                                        <div v-if="car.office_id!=demand_office_id" class="alert-danger px-2">
                                                Patří do lokality <b>{{ car.office }}</b>! Pro prodej musí být vozidlo převedeno.
                                        </div>
                                        <div class="p-1">
                                            <input-component label="Poznámka" v-model="selectedCarsNotes[car.id]" :id="'car_' + car.id + '_note'" placeholder="Informace k ceně, možné slevě, apod." name="" type="textarea" @change="carNoteChange(car.id, $event)">
                                            </input-component>
                                        </div>
                                    </div>


                                    <div class="col-md-1"  title="Zrušit vozidlo z nabídky">
                                        <div class="">
                                            <i class="far c-select-btn fa-check-square" @click="removeCarFromDemand(car)"></i>
                                        </div>
                                    </div>


                            </div>
                        </div>
                    </div>
                    </template>

                    <div class="row mt-5">
                        <div class="col">
                            <strong>Další údaje</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <input-component label="Testovací jízda" v-model="demand_test_drive" id="demand_test_drive" :disabled="true" placeholder="Má zákazník zájem o testovací jízdu?" name="demand_test_drive" type="text">
                                <template v-slot:append>
                                    <div class="input-group-append" >
                                        <span class="input-group-text d-inline-block" title="Testovací jízda proběhla dne ." @click.prevent="demand_test_drive = 'Testovací jízda proběhla '+today()+'.';change('test_drive')"><i class="fas fa-check-circle"></i></span>
                                        <span class="input-group-text d-inline-block" title="Zájem o testovací jízdu na den " @click.prevent="demand_test_drive = 'Zájem o testovací jízdu dne ';change('test_drive')"><i class="far fa-calendar-alt"></i></span>
                                        <span class="input-group-text d-inline-block" title="Nemá zájem o testovací jízdu." @click.prevent="demand_test_drive = 'Nemá zájem o testovací jízdu.';change('test_drive')"><i class="far fa-times-circle"></i></span>
                                    </div>
                                </template>
                            </input-component>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-6">
                            <!-- //způsob úhrady -->
                            <input-component label="Financování" v-model="demand_financing" id="demand_financing" :disabled="true" placeholder="Má zákazník zájem o financování?" name="demand_financing" type="text">
                                <template v-slot:append>
                                    <div class="input-group-append" >
                                        <span class="input-group-text d-inline-block" title="Ano má" @click.prevent="demand_financing = 'Zájem o financování, nabídka poskytnuta ';change('financing')"><i class="fas fa-check-circle"></i></span>
                                        <span class="input-group-text d-inline-block" title="Nemá zájem." @click.prevent="demand_financing = 'Vlastní prostředky, nemá zájem.';change('financing')"><i class="far fa-times-circle"></i></span>
                                    </div>
                                </template>
                            </input-component>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-6">
                            <input-component label="Pozvánka na prodejnu" v-model="demand_invitation" id="demand_invitation" :disabled="true" placeholder="Má zákazník zájem přijít na prodejnu?" name="demand_invitation" type="text">
                                <template v-slot:append>
                                    <div class="input-group-append" >
                                        <span class="input-group-text d-inline-block" title="Zákazník přijde na prodejnu." @click.prevent="demand_invitation = 'Má zájem.';change('invitation')"><i class="fas fa-check-circle"></i></span>
                                        <span class="input-group-text d-inline-block" title="Zákazník nevidí potřebu přijít na prodejnu." @click.prevent="demand_invitation = 'Nemá zájem.';change('invitation')"><i class="far fa-times-circle"></i></span>
                                    </div>
                                </template>
                            </input-component>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-6">
                            <!-- //způsob úhrady -->
                            <input-component label="Výkup ojetého vozu" v-model="demand_comission" id="demand_comission" :disabled="true" placeholder="Má zákazník zájem o výkup ojetého vozu?" name="demand_comission" type="text">
                                <template v-slot:append>
                                    <div class="input-group-append" >
                                        <span class="input-group-text d-inline-block" title="Ano zájem " @click.prevent="demand_comission = 'Vlastní vůz k výkupu';change('comission');"><i class="fas fa-check-circle"></i></span>
                                        <span class="input-group-text d-inline-block" title="Nemá zájem ." @click.prevent="demand_comission = 'Nemá zájem o výkup.';change('comission');"><i class="far fa-times-circle"></i></span>
                                    </div>
                                </template>
                            </input-component>
                                                    
                        </div>

                        <a :href="'/vykupni-vozy/volba-vozidla/'+customer.id" v-if="canCommission" onclick="return createCommission();">
                            <div class="btn-new" @click="createCommission" >
                                <i class="fas fa-hand-holding-usd icon-size"></i>Založit výkup
                            </div>
                        </a>
                        <div v-else class="alert alert-warning d-inline-box">
                           <i class="fas fa-info-circle"></i> Pro založení výkupu musíte vybrat/uložit zákazníka
                        </div>
                    </div>


                    <br>
                    &nbsp;
                </div>
        </demand-panel-component>

        <!-- end DEMAND ******************************************************************* -->



        <!-- DEAL -->
        <demand-panel-component id="4" :open="openTab(4)" :active="isActiveTab(4)" @active-tab="activeTab=4" @toggle-tab="toggleTab" v-if="demand_phase && canEdit">

                    <div slot="title">
                        <h2 class="d-inline-block"><i class="far fa-id-card m-2"></i>
                                <span >Stav</span>

                        </h2>

                    </div>
                    
                    <div slot="title">
                                <input
                                    id="query"
                                    v-model="demand_title"
                                    type="string"
                                    name="query"
                                    autofocus
                                    autocomplete="off"
                                    readonly onfocus="this.removeAttribute('readonly');"
                                >
                    </div>


                <div slot="content">






                    <br>
                    <div class="workflow-box" v-if="demand_phase">
                        <h3>Stav poptávky</h3>
                        <div>
                            Aktuální fáze: <em> <i :class="demand_phase['icon']"></i> {{ demand_phase['title'] }}</em>
                        </div>

                        <div v-if="demand_phase && demand_phase.id== 5 && deal" class="mt-2">
                            Případ: <a :href="deal.url"><i class="fas fa-phone-volume pr-1"></i>{{ deal.title}} </a>
                            <span class="badge badge-info p-2" v-if="deal.phase>''">{{ deal.phase }}</span>
                        </div>


                        <div v-if="demand_phase && demand_phase.id== 4" class="mt-2">
                            Vyberte vozidlo, které přidat do případu 

                                    <div class="c-selected-cars-container">
                                        <div class="c-selected-cars" v-for="car in selectedCarsLoaded" :key="car.id">


                                            <div class="row" >

                                                    <div class="col-md-1"  title="Zrušit vozidlo z nabídky"  v-if="car.office_id == demand_office_id">
                                                            <div @click="selectCarFinal(car.id)">
                                                                <i class="far c-select-btn" :class="{'fa-square':!isFinalSelected(car.id), 'fa-check-square':isFinalSelected(car.id)}"></i>
                                                            </div>
                                                    </div>
                                                    <div class="col-md-1"  title="Nelze přidat do nabídky" v-else>
                                                    </div>

                                                    <div class="col-md-1">
                                                        <div class="">
                                                            <img :src="car.img_title" width="80">
                                                        </div>
                                                    </div>
                                                    <div @click="showSelectedCar(car.id)" class="col-md-5 c-selected-cars-content py-1">
                                                        <div v-if="car.office_id!=demand_office_id" class="alert-danger px-2">
                                                                Patří do lokality <b>{{ car.office }}</b>! Pro prodej musí být vozidlo převedeno mezi salony a přidáno do poptávky nebo až do vytvořeného případu.
                                                        </div>

                                                        <strong>{{ car.title }}</strong>
                                                    </div>
                                                    

                                            </div>
                                        </div>
                                    </div>





                        </div>
                        <div v-if="demand_phase && demand_phase.id== 3">

                            <div class="row">
                                <div class="col-md-6" v-if="demand_cancel.length>0" style="padding-left:2.4rem;">  

                                    <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text" for="demand_cancel_id">Vyberte důvod</label>
                                    </div>
                                    <select class="custom-select" id="demand_cancel_id" v-model="demand_cancel_id" placeholder="Vyberte důvod ukončení">
                                        <option v-for="item in demand_cancel" :value="item.id" :key="item.id">{{ item.title }}</option>
                                    </select>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <!-- //způsob úhrady -->
                                    <input-component label="Poznámka" v-model="demand_cancel_description" id="demand_cancel_description" placeholder="Upřesněte důvod nezájmu zákazníka o nabídku" name="demand_cancel_description" type="text">
                                    </input-component>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-warning m-2" v-if="demand_phase && demand_phase.info>''">
                            <i class="fas fa-info-circle"></i> {{ demand_phase.info }}
                        </div>
                        <div class="alert alert-danger m-2" v-if="!(demand_type>0 && demand_type<4)">
                            <i class="fas fa-exclamation-triangle"></i> Není zvolen typ případu! Pokračujte prosím jeho volbou  v horní části.
                        </div>

                        <div class="col-md-4" v-if="customer.agreement==0 && demand_phase.id!=3">
                                    <input-component label="Souhlas se zasláním emailu" v-model="customer.agreement" id="customeragreement" name="customeragreement" type="switch" value=1 :class="{'text-danger':customer.agreement==0, 'text-success':customer.agreement==1}">
                                    </input-component>

                        </div>
                        <template v-if="(demand_phase.id!=3 || demand_cancel_id>0) && (demand_type>0 && demand_type<4)">
                            <div class="btn-new" v-for="(next,key) in demand_phase.next" :key="key" @click="changePhase(next.id, next.action_id)">
                                <i :class="next.icon"></i>   {{ next.title }}
                            </div>
                        </template>
                    </div>

                    <br>
                    
                    &nbsp;

                </div>

        </demand-panel-component>
        <!-- ¸END DEAL -->

    </div>
</template>



<script>
import DemandCustomerComponent from './DemandCustomerComponent.vue';
import UploadComponent from './UploadComponent.vue';
import DemandButtonComponent from './DemandButtonsComponent.vue';
import DemandPanelComponent from './DemandPanelComponent.vue';
import DemandCarSearch from './DemandCarSearch.vue';

export default {
    components: { DemandPanelComponent, DemandCarSearch, DemandButtonComponent, DemandCustomerComponent, UploadComponent },
    props: {
       },
    data: function() {
        return {
                storedVars:[
                    // 'customer','demand_id', 'demand_description', 
                    'demand_buttons', 'selectedButtons', 
                    'respect_office', 'respect_demand_type',
                    //'demand_contact_type', 'demand_title','demand_type', 'demand_test_drive',
                    // 'demand_financing','demand_comission', 
                    'order', 'order_asc', 'carentry'
                ],
                customer: {id:0},
                contacts:{},
                loadCustomer:null,
                admin:false,
                edit:true, //zda může editovat
                demand_id:null, //
                tabs:[
                    1,    // 0=zavreno/1otevreno
                    1,    //1 = první - zákazník
                    0,      //2 = první - vozidla
                    0,    //3 = první - navbídka
                    0,    //4 = první - deal
                ],
                carentry:"",
                activeTab:1,
                demand_title:"",
                demand_description:"",
                demand_test_drive:"",
                demand_invitation:"",
                demand_buttons:[],
                demand_phase:null,
                demand_contact_type:null, //jak zákazník kontaktoval - kvůli mystery
                demand_financing:"",
                demand_contact_id:"",
                demand_comission:"", //výkup
                demand_cancel:[],   //seznam důvodů k ukočneí poptávky
                demand_config:"",
                demand_config_title:"",
                demand_office_id:null,
                office_id:null,
                user_info:null,
                customer_id:null,


                demand_type:null, //jak zákazník kontaktoval - kvůli mystery
                
                respect_demand_type:1, //zda vyhledávat vozidla dle typu případu
                respect_office:1, //zda vyhledávat vozidla dle typu případu
                demand_cancel_id:null, //důvod ukončení
                demand_cancel_description:"", //POPIS
                searches:[],    //seznam všech vyhledávání
                changes:[],     //seznam změn od posledního uložení
                deal:{},
                customerRating:0,
                loadedCars:[],                //voidla v nabídce
                loadedDemand:null,
                categories:null,
                order:null,
                order_asc:1,    //1 nebo 0
                orderAll:null,
                selectedButtons:[],     //vybraná tlačítka
                selectedButtonsLoaded:[],
                selectedCarsNotes:{},
                selectedCarsInclude:{},
                selectedCarsLoaded:[],
                selectedCarsFinal:[], //pro vytvoření případu
                demand_errors:null,
                showSearchButton:false,

                ratingCalculation: [    //seznam true/false funkcí a počet bodů
                    { 
                            fun:'contactInfoFilled',
                            rating:30,
                    },
                    { 
                            fun:'carsFound',
                            rating:30,
                    },
                ],



        }
      },

        watch:{

            demand_id: function() {
                console.log("DEMAND_ID", this.demand_id);
                if (this.demand_id>0) {
                    if (this.$route.name!=='show_demand') {
                        this.$router.replace({ name: 'show_demand', params: { demand_id: this.demand_id }});
                    }
                    } else {
                    this.$router.replace({ name: 'demand'});
                }

            },
            customer_id: function() {
                //console.log("CUSTOMER_ID", this.customer_id);
                if (this.customer_id>0) {
                    Vue.set(this.customer, 'id', this.customer_id);
                    this.loadCustomer = this.customer_id;
                }

            },

            office_id: function() {
                if (this.demand_office_id ===null) {
                    this.demand_office_id = this.office_id;
                }
            },
            user_info: function() {
                //console.log('NACTEN USER', this.user_info);
            },

            selectedButtons:function() { //zakliknuta tlačítka
             //   console.log('NOV8 TLACITKA', this.selectedButtons);
                //načti nová data o vozidlech
            },

            //


            loadedCars: function() {
                // console.log('COMPARE BUTTONS ALIGNED');
                this.selectedButtonsLoaded = [];
                this.selectedButtonsLoaded.push(...this.selectedButtons);

                //uložení všeho
                this.$store.commit('setCars', this.loadedCars);
                if (this.canEdit) {
                    this.saveDemand(999);
                    //console.log('SAVE');
                }
            },

            loadedDemand: function() {
                if (this.loadedDemand!==null) {
                    //nahraje všechna data kam patří + vymaže dočasné věci
     
     //o zákazníkovi
                    //o demandu - descr, title, cust. contact
                    let d = this.loadedDemand['data'];

                    //nacte udaje o nabidce
                        const dem = [ //demand
                            'id',
                            'title',
                            'description',
                            'type',
                            'test_drive',
                            'invitation',
                            'financing',
                            'comission',
                            'contact_type',
                            'config',
                            'config_title',
                            'office_id',
                        ];
                        dem.forEach(item => {
                            this['demand_' + item] = d['demand'][item];
                        });

                    //nacte udaje o zakaznikovi
                        const cust = [ 'id', 'title', 'type', 'email', 'phone', 'name', 'firstname', 'psc', 'city', 'city_id', 'street', 'ic', 'gender', 'country_id', 'rc', 'frame_contract', 'agreement', 'contact_id'];
                        
                        cust.forEach(item => {
                            // console.log('CUST' + item, d['demand']['customer_' +item]);
                            //this.customer[item] = d['demand']['customer_' +item];
                            this.$set(this.customer, item, d['demand']['customer_' +item]);
                        });
                            this.$set(this.customer, 'contact_id', d['demand']['contact_id']);

                    //buttons
                        this.selectedButtons = d['selected_buttons'];
                        this.contacts = d['customer_contacts'];

                    //selected cars
                        this.$store.commit('setSelectedCars',d['selected_cars']);

                    //a načte data - 
                        //this.loadOffer(false,'cars_load_only');
                        this.fetchData('load_selected_cars');

                    //vymaže = uvolní data
                    this.loadedDemand = null;

                }

                
            },
            selectedCars: function() {
                console.log('000000000000000000   změna selected cars');
                this.fetchData('load_selected_cars');

                if (this.selectedCars && this.selectedCars.length==1) {
                    console.log('SELECTED ZMEN - zaskrtavam', this.selectedCars.length);
                    this.selectedCarsFinal.push(this.selectedCars[0]);
                    
                }
            },


        },
        computed: {
            rating: function () {
                return this.$store.getters.getRating;
                return this.calculateRating();
            },

            correctCarShop: function() {

            },

            //zda může editovat poptávku
            canEdit: function () {
                return this.edit;
            },

            //zda může založit výkup
            canCommission: function () {
                return this.customer.id>0;
            },

            //pro povoleni musí být 99
            showWaiting: function() {
                let r = 0;
                if (this.demand_phase && this.customer) {
                    if (this.demand_phase['id']==6) {
                        r = 6;
                    } else if (
                            this.demand_id>0 && [1,2,3,4].indexOf(this.demand_phase['id'])!==-1
                            &&
                            (!this.loadedCars || this.loadedCars.length===0) 
                            &&
                            (this.demand_buttons && this.demand_buttons.length>0)
                            &&
                            (this.customer 
                                && this.customer.email && this.customer.email.length>5 
                                && this.customer.phone.length>8 
                                && this.customer.name.length>1)
                    ) {
                        r = 99; //vše pro odeslání
                    } else if ([1,2,3,4].indexOf(this.demand_phase['id'])==-1) {
                        r = 1;  //nepovolená fáze
    
                    } else if ((this.customer.email&& this.customer.email.length<6) && (this.customer.phone && this.customer.phone.length<9) && (this.customer.name && this.customer.name.length<2)) {
                        r = 2; //neúplné údaje
                    }
                }
                //zobraz waiting pokud načtené vozy = 0 a buttons >0 a není aktivované tlačítko na hledání...aby ho musel spustit
                //současně musí být zadán zákazník a uložená poptávka
                return  r;

            },

            selectedCars: function() {
                return this.$store.getters.getSelectedCars;
            },

            cars: function() {
                return this.$store.getters.getCars;
            },

            buttonsChanged:function() {
                //compare buttons
                if (this.selectedButtons.length<1 ) {
                    return true;
                }
                if (this.showSearchButton) {
                    return false;
                }
                let c = this.compareArrays(this.selectedButtonsLoaded, this.selectedButtons);
                
                return c;
            }

        },

        mounted: function () {
            if (!this.categories) {
                this.updateCategory();
            }

            // console.log('ROUTE',this.$route.name);
            if (this.$route.name=="show_demand" && (this.demand_id = this.$route.params.demand_id)>0) {
                this.loadDemand(this.demand_id);
            } else {
                //pokud je úplně nový pak ano, jinak ?
                this.restoreDataLocally(this.storedVars);
            }
            if (this.$route.name=="customer_demand" && this.$route.params.customer_id>0) {
                this.loadCustomer = this.$route.params.customer_id;
            }

            // this.$set(this, 'customer', this.customer);
            //this.loadOffer(false,'cars_load_only');
            //console.log('CUSTOMER DATA',this.customer);
            if (this.selectedCars && this.selectedCars.length>0) {
                if (!this.selectedCarsLoaded  || this.selectedCarsLoaded.length!=this.selectedCars.length) {
                    this.fetchData('load_selected_cars');
                }

            }
        },
methods: {
            //
            uploadFile() {

                let file = this.$refs.file.files[0]
                if(file>"") {
                    console.log('UPLOAD', file);
                }
            },

            createCommission() {
                if (confirm('Založit výkup vozidla?')) {
                    this.demand_comission = 'Vlastní vůz k výkupu';
                    this.saveDemand(999);
                    return true;
                }
            },
            //nastaví contact type
            setContactType: function (id){
                this.demand_contact_type=id;
            },


            //nastaví typ prípadu
            setDemandType: function (id){
                this.demand_type=id;
                this.showSearchButton = true;
            },


            //před převedením vozidla do případu - toggle 
            selectCarFinal:function(id){
                    let i = this.selectedCarsFinal.indexOf(id);
                    //pokud v seznamu -> vyjme jej
                    if (i>-1) {
                        this.selectedCarsFinal.splice(i,1);
                    }
                    else {
                        this.selectedCarsFinal.push(id);
                    }                  
                
            },

            //zda je vozidlo vybráno pro převod do případu
            isFinalSelected: function(id) {
              return  this.selectedCarsFinal.indexOf(id)!==-1;
            },


            //vyjme vozidlo z nabídky
            removeCarFromDemand: function(car) {
                    if (confirm('Vyjmout vozidlo ' + car.title + ' z nabídky?')) {
                        this.clearSelectedCars(car.id)
                    }
            },
            //z mystery jde na ID
            goTo:function(id){
                if (!this.openTab(id)) {
                    this.toggleTab(id);
                }
                window.location = (""+window.location).replace(/#[A-Za-z0-9_]*$/,'')+"#tab"+id                
            },
            tabClass:function(id) {
                let c = (this.isActiveTab(id))?"c-active ":"";
                c+=this.openTab(id)?'c-open':'c-closed';
                return c;
            },

            openTab:function(tabId){
                return this.tabs[tabId] ===1;
            },

            closeTabsExcept:function(tabId){
                this.tabs.forEach((t, key)=>{
                    this.tabs[key]=(tabId==key)?1:0;
                });
            },

            // clickTab:function(tabId){
                //     //otevře tab na který klinul a zavře ostatní
            //     this.closeTabsExcept(tabId);
            //     this.activeTab=tabId;

            //     return this.tabs[tabId] ===1;
            // },


            isActiveTab:function(tabId){
                return this.activeTab==tabId;
            },

            toggleTab: function(id) {
                if (this.openTab(id)) { //zavři
                    console.log('ZAVRIT TAB',id);
                    this.$set(this.tabs, id, 0);
                } else {
                    console.log('OTEVRIT TAB', id);
                    this.$set(this.tabs, id, 1);
                }
                // console.log('TOGGLE',this.openTab(id));
            },

            carNoteChange:function(carId, event) {
                //zaznamenat změnu v poli do nějaké proměnné
                // console.log('switch' + carId, event);
                this.change('car_note');
            },

            includeCar: function(carId, event) {
                console.log('switch' + carId, event);
                this.change('car_deal_selected');
            },

            resetChanges:function(){
                this.changes = [];
            },
            change:function(name){
                this.changes.push(name);
            },

            showSelectedCar: function(id) {
                this.saveDemand(999);
                this.$router.push({name:'car_show_selected', params:{car_id:id, demand_id:this.demand_id}});
            },

            selectedCar: function(id) {
                this.$store.commit('changeSelectedCars', id);    
                this.change('cars');
                this.storeDataLocally();
            },
            clearSelectedCars:function (id = null){
                if (id===null) {
                    this.$store.commit('setSelectedCars', []);
                } else {
                    this.selectedCar(id);
                }
                this.change('cars');
                this.storeDataLocally();
            },

            storeDataLocally:function() {
                this.localStorageSave(this.storedVars,'UP_');
            },
            restoreDataLocally:function() {
                this.localStorageRestore(this.storedVars,'UP_'); 
                
            },

            updateCategory:function(){
                this.fetchData('categories',null)
            },
            listen:function(key) {
                switch (event.keyCode) {
                    case 38:
                    this.activeTab--;
                    break;
                    case 40:
                    this.activeTab++;
                    break;
                }
            },

            buttonChange: function(selectedButtons) {
                this.selectedButtons = selectedButtons;
                this.storeDataLocally();
            },

            buttonClear: function(selectedButtons) {
                this.selectedButtons = [];
                this.storeDataLocally();
            },

            buttonTextChange: function(text) {
                this.demand_buttons = text;
            },

            calculateCustomerRating: function(payload){
                this.customerRating = payload;
            },

            //vytvoří název poptávky ze zákazníka
            getDemandTitle: function(customer) {
                if (customer) {
                    if (customer.type == 1) {
                        return customer.name + ' ' + customer.firstname;
                    } else {
                        return customer.title;
                    }
                }
            },

            customerUpdate: function(payload){
                this.customer = payload;
                //console.log('DEMAND - UPDATED customer', payload);

                this.demand_title = this.getDemandTitle(this.customer);
                this.change('customer');
                this.storeDataLocally();
            },
            carsFound: function() {
                if (this.cars && this.cars.length>0) {  //vyplněny údaje zákazníka
                    return true;
                }
            },


            sortCars() {
                let col = this.orderAll.find(obj => {  return obj.id == this.order});
                //this.order
                //this.order_asc
                console.log('ORDER', col);
                if (col && col.column>"") {
                    this.$store.commit('orderCars',{
                        order:this.order_asc,
                        column:col.column,
                    });
                }
            },

            orderBy:function(id){
                this.order = id;
                this.sortCars();
            },

            orderAsc:function(id){
                this.order_asc = id;
                this.sortCars();
            },

            //vymaže všechny vstupní data pro novou poptávku
            clearFields:function() {
                    this.customer = {id:null, type:1};
                    this.demand_id = null;
                    this.demand_description = "";
                    this.demand_title = "";
                    this.demand_buttons = [];
                    this.demand_contact_type = 0;
                    this.demand_type = 0;
                    this.demand_test_drive = '';
                    this.demand_financing = '';
                    this.demand_comission = '';
                    this.searches = [];
                    this.$store.commit('setSelectedCars',[]);
                    this.$store.commit('setCars',[]);
                    this.selectedButtons = [];
                    this.selectedButtonsLoaded = [];
            },
            //vymaže všechny vstupní data pro novou poptávku
            newDemand:function() {
                if (confirm('Opravdu smazat všechna data a připravit pro novou nabídku?')) {
                    this.clearFields();
                    this.edit = true;
                    this.resetChanges();        
                }
            },

            showFoundCars: function (id) {

                this.saveDemand(999);

                this.$router.push({ name: 'car_show', params: { car_id: id, demand_id:this.demand_id }})                
            },

            //odešle všechny vstupní data na server
            
            saveDemand:function(action_id = null) {
                //console.log('ULOZIT NABDKU');
                if (this.canEdit) {
                    this.fetchData('demand_save', action_id);
                    this.resetChanges();
                }

            },
            saveCustomer:function() {
                //console.log('ULOZIT NABDKU');
                if (this.canEdit) {
                    this.fetchData('demand_customer_save', 999);
                    this.resetChanges();
                }

            },

            waitFor:function() {
                //console.log('ULOZIT NABDKU');
                if (confirm('Opravdu zapnout hlídání')) {
                    if (this.canEdit) {
                        this.fetchData('wait_for');
                        this.resetChanges();
                    } else {
                        alert('Bohužel nemůžete upravovat poptávku');
                    }
                }

            },

            changePhase: function(phase,actionId) {
                //console.log('POSUN Z ' + phase,actionId);


                this.saveDemand(actionId);
            },

            loadOfferButtons: function () {
                this.loadOffer(true);
            },
            //načte nabídku podle buttons
            loadOffer: function(buttons = false, command='cars'){
                //načte data o vozidlech přes API
                if (this.selectedButtons) {
                    this.fetchData(command,buttons);
                    this.storeDataLocally();
                    //this.resetChanges(); - neukládá data o nabídce
                }
            },


            //vyhledá vozidlo
            findCar:function(carentry){  //přidá fulltextem nalezené vozidlo do vybraných vozidel pro zákazníka
                //zavolá hledání vozidla - ne přes buttons
                this.carentry = carentry;
                if (carentry>"") {
                    this.fetchData('search_cars',carentry);
                    this.closeTabsExcept(2);
                    this.storeDataLocally(); 
                    this.resetChanges();
                }

            },

            //přidá vozidlo z vyhledávacího formuláře do nalezených vozů
            addCar: function(car) {
                console.log('ADD CAR ',car);
                if (car && car.id>0) {
                    //přidá do vozidel - do loaded cars

                    this.loadedCars.push(car);
                    
                    //a rovnou jej vybere - do selected cars - zavolá mutator $store.changeSelectedCars
                    this.$store.commit('changeSelectedCars',car.id);
                    this.change('cars');
                    this.storeDataLocally(); 
                    this.closeTabsExcept(3);
                    //this.resetChanges();
                }

            },



            loadDemand: function(id){
                //načte data o nabídce
                    this.fetchData('demand_load', id);
                    this.resetChanges();
            },


            contactInfoFilled: function() {
                if (this.customer && this.customer.id>0) {  //vyplněny údaje zákazníka
                    return true;
                }
                return this.validEmail(this.customer.email) && this.validPhone(this.customer.phone);
            },

            //
            calculateRating: function() {
                let r = 0;
                this.ratingCalculation.forEach((item)=>{r+=this[item.fun]()?item.rating:0;});
                // console.log(r);
                return r;
            },

            //přines data z DB
            fetchData:function(command, data) {

                let inputData = {command:command};
                let results = {   apiData:'data', successApi:'success', errors:'errors'};
                switch (command) {
                    case 'cars_load_only': //zákazníci

                    case 'search_cars': //fulltextové vyhledání vozidla
                            inputData.api_id = 'demandCarApi';

                            //přibalí informace o poptávce apod
                            inputData.demand_id = this.demand_id;
                            inputData.demand_description = this.demand_description;
                            inputData.customer = this.customer;
                            inputData.order_by = this.order;
                            inputData.order_asc = this.order_asc;

                            inputData.limit = 20;
                            inputData.carentry = data;

                            results.loadedCars = "cars";
                            results.demand_errors = "errors";
                        break;

                    case 'cars': //vozidla - načte dle buttons
                    case 'wait_for': //uloží demand a posune do stavu čekání
                            inputData.api_id = 'demandApi';

                            //přibalí informace o poptávce apod
                            inputData.demand_id = this.demand_id;
                            inputData.demand_description = this.demand_description;
                            inputData.customer = this.customer;
                            inputData.order_by = this.order;
                            inputData.order_asc = this.order_asc;
                            inputData.limit = 20;

                            inputData.demand_title = this.demand_title;
                            inputData.buttons = this.selectedButtons;        
                            inputData.demand_contact_type = this.demand_contact_type;
                            if (this.respect_demand_type==1) { //předá data o typu jen v případě že je nemá ignorovat
                                inputData.demand_type = this.demand_type;
                            }
                            inputData.office = this.respect_office;
                            inputData.cars = this.selectedCars;
                            if(data) {
                                inputData.action_id = 901; //znamená, že má uložit novou nabídku
                            } else {
                                inputData.action_id = null;
                            }

                            results.loadedCars = "cars";
                            results.demand_buttons = "button_text"; //načte info o tlačítkách
                            results.demand_errors = "errors";
                            results.demand_id = "demand_id";

                            results.demand_phase = "demand_phase";
                            this.showSearchButton = false;
                        break;
                
                    case 'categories': //tlačítka
                            inputData.api_id = 'DemandCategoryApi';
                            results.categories = "categories";
                            results.office_id = "office_id";
                            results.user_info = "user_info";
                            results.orderAll = "order";
                        break;
                   

                    case 'demand_save':
                    case 'demand_customer_save':
                            inputData.api_id = 'demandSaveApi';
                            inputData.demand_id = this.demand_id;
                            inputData.demand_description = this.demand_description;
                            inputData.customer = this.customer;
                            inputData.action_id = data; //posun workflow

                            inputData.demand_title = this.demand_title;
                            inputData.buttons = this.selectedButtons;        
                            inputData.demand_contact_type = this.demand_contact_type;
                            inputData.demand_type = this.demand_type;
                            inputData.demand_test_drive = this.demand_test_drive;
                            inputData.demand_invitation = this.demand_invitation;
                            inputData.demand_contact_id = this.customer.contact_id;
                            inputData.demand_financing = this.demand_financing;
                            inputData.demand_comission = this.demand_comission;
                            inputData.demand_cancel_id = this.demand_cancel_id;
                            inputData.demand_config = this.demand_config;
                            inputData.demand_config_title = this.demand_config_title;
                            inputData.demand_cancel_description = this.demand_cancel_description;
                            inputData.selected_cars_final = this.selectedCarsFinal;
                            inputData.cars = this.selectedCars;
                            inputData.rating = this.rating;


                            // zabalit i note a selected cars
                            inputData.selected_cars_notes = this.selectedCarsNotes;
                            inputData.deal_selected_cars = this.selectedCarsInclude; //jen pro vytvoření
                            //console.log('ODESILAM', inputData);
                            results.demand_phase = "demand_phase";
                            results.deal = "deal";
                            results.demand_id = "demand_id";
                            results.customer_id = "customer_id";
                            results.demand_cancel = "demand_cancel";
                            results.demand_errors = "errors";
                        break;
                    case 'demand_load':
                            inputData.api_id = 'demandLoadApi';
                            inputData.demand_id = data;
                            inputData.api_data = 'loadedDemand';
                            results.demand_phase = "demand_phase";
                            results.loadedDemand = "data";
                            results.admin = 'admin';
                            results.edit = 'edit';
                            results.deal = 'deal';
                            results.demand_cancel = "demand_cancel";
                            results.selectedCarsLoaded = "selectedcars";
                        break;

                    case 'load_selected_cars':
                            inputData.api_id = 'demandCarApi';
                            inputData.car_id = data;       
                            inputData.selected_cars = this.selectedCars;
                            results.selectedCarsLoaded = "selectedcars";
                        break;

                }
                
                var d = this.getApiData( 
                            inputData,
                            results
                );

            },


        },
        

}
</script>


<style>


    .workflow-box {
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
        background-color: rgb(189, 189, 189);
        padding: 1rem;
        border-radius:7px;
        border: silver 1px solid;
        margin:2rem;

    }


    /* CUSTOMER */


    .c-selected-cars{
            background-color:white;
            border:silver 1px solid;
            /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
    }
    .c-selected-cars-img{
            background-color: silver;
            min-width: 100px;
    }



/* 
    .c-cust {
        padding: 2rem;

        -webkit-transition: all 1s ease-out;
        -moz-transition: all 1s ease-out;
        -o-transition: all 1s ease-out;
        transition: all 1s ease-out;
    }
    .c-closed {
        max-height:6rem; 
        overflow:hidden;        
        min-height:0;

    }
    .c-open{
        max-height: 1000px;
        overflow: scroll;
        min-height:700px;
    } */

    .c-active {
        background-color: rgb(230, 230, 230);

        -webkit-transition: all 1s ease-out;
        -moz-transition: all 1s ease-out;
        -o-transition: all 1s ease-out;
        transition: all 1s ease-out;
    }

    .loading {
        position: fixed;
        left:0;
        right:0;
        top:0;
        font-size: 2rem;
        background-color:transparent;
        color: yellow;
        text-align: center;
        z-index: 1000;
        

    }
    .demand-container{
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        border: rgb(196, 196, 196)  1px solid;
        border-radius: 3px;
      
    }

.contact-type-alert {
    background-color: rgb(255, 71, 71);
    padding: 5px;
    margin-top:3px;
}
.contact-type-box {
    /* border: 1px solid rgb(221, 221, 221); */
    display: inline-block;
    
}
.contact-type {
    width: 4rem;
    margin: 3px;
    height:3rem;
    border-radius: 5px;
    font-size: 1.7rem;
    display: inline-block;
    text-align: center;
    background-color: rgb(200, 238, 215);
    color: silver;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;

}

.contact-type-active {
    background-color: rgb(94, 190, 91);
    color: rgb(128, 0, 0);
    box-shadow:0;

}

.title-panel {
    padding:5px;
}

</style>
