
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');
require('webpack-jquery-ui/datepicker');

require( 'datatables.net-buttons-bs4' )();
require('./datatables.js')
require( 'datatables.net-buttons/js/buttons.colVis.js' )();
require( 'datatables.net-buttons/js/buttons.flash.js' )();
require( 'datatables.net-buttons/js/buttons.html5.js' )();
require( 'datatables.net-buttons/js/buttons.print.js' )();
// var Chart = require('chart.js')
import Chart from 'chart.js';
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

window.Vue = require('vue');



/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


import VueRouter from 'vue-router';
import { routes } from './routes';
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
      return {x: 0, y: 0};
    }
});

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//specifické objekty

//možno na globální úrovni nebo jen k vybraným komponentám
//import Mixin from './global.js';
//Vue.mixin(Mixin);


//VUEX pro snazší stílení globálních dat
import Vuex from 'vuex';
import 'es6-promise/auto';
Vue.use(Vuex);

//definice store
import store from './store';    //store/index.js




Vue.component('discounts-component', require('./components/DiscountsComponent.vue').default);
Vue.component('statistics-component', require('./components/StatisticsComponent.vue').default);
Vue.component('rental-statistics-component', require('./components/RentalStatisticsComponent.vue').default);
Vue.component('table-component', require('./components/TableComponent.vue').default);
Vue.component('cars-table-component', require('./components/CarsTableComponent.vue').default);
Vue.component('rentals-table-component', require('./components/RentalsTableComponent.vue').default);
Vue.component('graph-component', require('./components/GraphComponent.vue').default);
Vue.component('pie-graph-component', require('./components/PieGraphComponent.vue').default);


Vue.component('demand-component', require('./components/DemandComponent.vue').default);
Vue.component('demand-customer-component', require('./components/DemandCustomerComponent.vue').default);
Vue.component('demand-buttons-component', require('./components/DemandButtonsComponent.vue').default);
Vue.component('demand-cars-component', require('./components/DemandCarsComponent.vue').default);
Vue.component('demand-mystery-component', require('./components/MysteryComponent.vue').default);

// Vue.component('sheet-component', require('./components/SheetComponent.vue').default);
Vue.component('modal-component', require('./components/ModalComponent.vue').default);
Vue.component('input-component', require('./components/InputComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//společné metody a hodnoty
import Mixin from './global.js';

Vue.mixin(Mixin);

const app = new Vue({
    el: '#app',
    store:store,
    beforeCreate() { this.$store.commit('initialiseStore');},
    router,


    // mixins: [Mixin],
});
