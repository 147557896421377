
import DemandComponent from './components/DemandComponent.vue';
import CarComponent from './components/CarComponent.vue';

export const routes = [
    { path: '/poptavka', component: DemandComponent, name: 'demand' },
    { path: '/poptavka/:demand_id', component: DemandComponent, name: 'show_demand' },
    { path: '/poptavka/zakaznik/:customer_id', component: DemandComponent, name: 'customer_demand' },
    { path: '/poptavka/vozidlo/:car_id/:demand_id', component: CarComponent, name: 'car_show' },
    { path: '/poptavka/vybrana-vozidla/:car_id/:demand_id', component: CarComponent, name: 'car_show_selected' },
  
];