<template>
    <div class="my-2" :class="{'alert m-4 alert-light border shadow':historyindex!==null, '':historyindex===null}">

            <a v-if="historyindex!==null" href="" class="btn btn-sm btn-danger m-1 p-1" @click.prevent="deleteHistory(historyindex)" title="Smazat"><i class="far fa-trash-alt"></i></a>
        <b style="font-size:1.3rem;">{{ stattitle }}</b>


        <div class="row">
            <div :class="{'col-12':hideHistoryGraphs, 'col-9':!hideHistoryGraphs}">
                <table class="simple-table w-100">
                    <!-- head -->
                    <tr>
                        <th>
                            <div class="float-right m-1">
                                <small>
                                    Průměr <input type= "checkbox"  v-model="average">
                                </small>
                            </div>
                            <div class="float-right m-1">
                                <small>
                                    Očekávání <input type= "checkbox"  v-model="expectation">
                                </small>
                            </div>

                        </th>

                        <th v-for="fun in statFuns" class="bg1" v-bind:key="fun.id">
                            <div class="w100 text-center border" :class="{'bg':countColumn==fun.id, 'bg1':countColumn!=fun.id}">
                                <a href="#" @click.prevent="countColumn=fun.id">{{fun.title}}</a>
                            </div>
                        </th>
                    </tr>

                    <!-- body  TR - pro všechny pivots-->
                      <tr v-for="(pivot,index) in pivots" v-bind:key="pivot" @click="displayCars(stat[0][index]['cars'])">
                            <th>{{ pivot }}</th>
                            <!-- jednotlivé položky -->
                            <template v-if="stat[0][index]" v-for="fun in statFuns">
                                <td v-if="(average&&('count'!=fun.id)&&!fun.no_average) || fun.no_sum" v-bind:key="fun.id">
                                    {{ fun.formatFun(stat[0][index][fun.id]/stat[0][index]['count']) }}
                                </td>
                                <td v-else v-bind:key="fun.id">{{ fun.formatFun(stat[0][index][fun.id]) }}</td>


                            </template>
                      </tr>

                    <!-- suma  - stat - 0, 1 pro všechny values-->
                    <tr>
                            <th>Součet</th>
                            <!-- jednotlivé položky -->
                            <template v-for="fun in statFuns">
                                <td v-if="(average&&('count'!=fun.id)&&!fun.no_average) || fun.no_sum" v-bind:key="fun.id">
                                    {{ formatCurrency(stat[0][fun.id]/stat[0]['count']) }}
                                </td>
                                <td v-else v-bind:key="fun.id">
                                    {{ formatCurrency(stat[0][fun.id]) }}
                                </td>

                            </template>
                    </tr>
                </table>
                <div v-if="this.fun && historyindex===null" class="my-1 alert alert-warning p-1 border">
                    <strong>{{ fun.title }}</strong> - {{ fun.desc }}
                </div>
            </div>

            <div :class="{'d-none':hideHistoryGraphs, 'col-3':!hideHistoryGraphs}">
                <pie-graph-component :key="historyindex" :statPivots="statPivots" :statFuns="statFuns" :stat="stat" :pivots="pivots" :historyindex="historyindex" :selectedColumn="countColumn" :average="average">
                </pie-graph-component>


            </div>
        </div>


    </div>


</template>



<script>
// import mixin from 'mixin.js';

    export default {
      props: {
        statPivots:Array,
        statFuns:Array,
        stat:Object,
        pivots:Object,
        stattitle:String,
        historyindex:0,
        selectedColumn:String,
        hideHistoryGraphs:Boolean,
        averageAvailable:Boolean,

       },

    data: function() {
        return {
            average:false, // zda je přepnuto na průměrnou cenu
            countColumn:'count', //sloupec s průměrnou cenou ze stat funs
            fun:{}, //zvolená funkce
        };
    },

      computed: {

      }, //computed

      methods: {

          deleteHistory:function(index) {
            this.$emit('delHistoryEvent', index);
          },

          displayCars:function(cars) {
            //   console.log('CLICKED - display', cars);
            this.$emit('displayCarsEvent', cars);
          },

        findStatFun: function(funId) {
            for (const fun of this.statFuns) {
                if (funId===fun.id) {
                    return fun;
                }
            }
        },



      }, // METHODS


      created:function(){
        // console.log(stat);
        // console.log(stattitle);
        this.countColumn = this.selectedColumn;
      }, //CREATED

      mounted:function(){
      }, //MOUNTED

      watch:{

        countColumn: function() {
            this.fun = this.findStatFun(this.countColumn);

        }
      }, //WATCH

    }
</script>

<style>


</style>
