<template>
    <div>
        <div class="main-container">
            <div class="loading" v-if="$store.getters.loading">
                <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
                </div>
            </div>



            <div id="top"> <!-- MAIN DIV - card -->
                <div class="title-panel">
                    <div class="btn-new" @click="goTo(-1)" v-if="carLeft" :title="carLeft.title">
                    <i class="fas fa-arrow-alt-circle-left icon-size"></i> Předchozí
                    </div>

                    <div class="btn-new" @click="demand_id>0?$router.push({ name: 'show_demand', params: {demand_id:demand_id}}):$router.push({ name: 'demand', params: {}})">
                    <i class="fas fa-list-alt"></i>
                    Návrat na poptávku
                    </div>

                    <div class="btn-new"  @click="goTo(1)" v-if="carRight" :title="carRight.title">
                    <i class="fas fa-arrow-alt-circle-right icon-size"></i> Následunící
                    </div>                    

                </div>

                <div v-if="car===null || car===undefined">
                    vozidlo se načítá
                </div>

                <div v-else class="car-card">
                    <div class="car-card-inner">
                        <h2>Vozidlo {{ car.title }}</h2>
                        <div class="row">
                            <div class="col-md-6">

		<!-- $fields = [
			'id','title','type', 'price', 'status', 'year', 'month','tax','odometer','special_offer_discount','photos'
		];

 -->


                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Uvedení do provozu
                                        </div>
                                        <div class="col-md-7 item-prop">
                                            {{ car.month }}/{{ car.year}}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            odpočet DPH
                                        </div>
                                        <div class="col-md-7 item-prop">
                                            {{ car.tax==1?"ANO":"NE" }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Nájezd
                                        </div>
                                        <div class="col-md-7 item-prop">
                                            {{ car.odometer }} km
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Skladem
                                        </div>
                                        <div class="col-md-7 item-prop">
                                            {{ car.status==2?"ANO":"REZERVACE" }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Typ
                                        </div>
                                        <div class="col-md-7 item-prop">
                                            <span v-if="car.type==1">Ojetý vůz</span>
                                            <span v-else>Nový vůz</span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Cena
                                        </div>
                                        <div class="col-md-7 item-prop">
                                            {{ formatCurrency(car.price) }} Kč
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Sleva
                                        </div>
                                        <div class="col-md-7 item-prop">
                                            {{ formatCurrency(car.special_offer_discount) }} Kč
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Motor
                                        </div>
                                        <div class="col-md-7 item-prop">
                                            {{ car.motor }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Palivo
                                        </div>

                                        <div class="col-md-7 item-prop">
                                            {{ car.fuel }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Barva
                                        </div>

                                        <div class="col-md-7 item-prop" > <!--   :style="{backgroundColor:car.color_html}" -->
                                            {{ car.color }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Lokalita
                                        </div>

                                        <div class="col-md-7 item-prop">
                                            {{ car.office }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Výbava
                                        </div>

                                        <div class="col-md-7 item-prop">
                                            {{ car.equipment }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            VIN
                                        </div>

                                        <div class="col-md-7 item-prop">
                                            {{ car.vin }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            SPZ
                                        </div>

                                        <div class="col-md-7 item-prop">
                                            {{ car.spz }}
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            <a :href="'\\cars\\'+car.id">Interní DB</a>
                                        </div>

                                        <div class="col-md-7 item-prop">
                                            {{ car.id }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5 item-prop-desc">
                                            Výběr
                                        </div>

                                        <div class="col-md-7 item-prop">
                                                <div @click="selectedCar(car.id)"><i class="far c-select-btn" :class="{'fa-square':!isSelected, 'fa-check-square':isSelected}"></i></div>
                                        </div>
                                    </div>




                            </div>


                            <div class="col-md-6">
                                <div class="title-img">
                                    <img :src="car.img_title" alt="" height="100%" width="100%">
                                </div>
                                
                            </div>
                        </div>

                        <!-- FOTOGALERIE ****************************************************************************** -->
                        <div class="gallery pt-5" v-if="car.photos&& car.photos.length>0">

                            <h2>
                                Fotografie
                            </h2>
                            <div class="row no-gutters">

                                <div class="col-md-4 col-lg-3 col-xl-2 p-0" v-for="img in car.photos" :key="img.id">

                                    <a class="elem" :href="img.url" data-lcl-author="" :data-lcl-thumb="(img.thumb>'')?img.thumb:img.url">
                                    <span :style="{backgroundImage:'url(' + ((img.thumb>'')?img.thumb:img.url) + ')'}"></span>
                                    </a>
                                </div>                                                               
                            </div>
                        </div> <!--  KONEC GALLERY-->



                    </div>

                </div>
            </div>    



            <!-- PANEL *************************************************************************** -->
            <div class="cars-panel" :class="{'cars-panel-open':carsPanel==1}" style="padding:0px" v-if="cars&&cars.length>0">
                <div class="title-panel">
                    <div class="btn-new" @click="togglePanel">
                        <i class="fas fa-arrows-alt-h"></i>
                    </div>
                    <strong v-if="selected">Vybraná vozidla</strong>
                    <strong v-else>Vozidla</strong>
                </div>
                <div class="flex-column" >
                    

                    <div v-for="c in cars" v-bind:key="c.id" class="list-cars" :class="{'list-cars-active':c.id==activeCar}" @click="selectCar(c.id)">
                        <div class="list-cars-img" >
                            <img :src="c.img_title" height="100%" width="100%">
                        </div>
                        <div class="list-cars-title">
                                {{ c.title }}
                        </div>
                    </div>

                </div>
            </div>




        </div>
        <div>

        </div>
    </div>
        
</template>


<script>

// <!-- GALLERY  -  LIGHTBOX -->
// <s c ript src="https://zakazky.ptam.cz/vendor/gallery/js/lc_lightbox.lite.js" type="text/javascript"></s c ript>
// <s c ript src="https://zakazky.ptam.cz/vendor/gallery/lib/AlloyFinger/alloy_finger.min.js" type="text/javascript"></s c ript>
// <s c ript type="text/javascript">
//   $(document).ready(function(e) {

//     // live handler
//     lc_lightbox('.elem', {
//       wrap_class: 'lcl_fade_oc',
//       gallery: true,
//       fullscreen: true,
//       fs_img_behavior: 'fit',
//       fs_only: 500,
//       browser_fs_mode: true,
//       txt_toggle_cmd: false,


//       thumb_attr: 'data-lcl-thumb',
//       skin: 'minimal',

//       radius: 0,
//       padding: 0,
//       border_w: 0,
//     });


//   });
// </s c ript>






    export default {

      props: {
          },
      data: function() {
          return {
            car_id:0, //id vozidla
            demand_id:0, //id poptavky
            car:null,
            selectedcars:[],
        }
      },

        computed: {
            carsPanel:function(){
                let r = this.$store.getters.getCarsPanel;
                if (r>0) {return 1;} else {return 0;}
            },
            isSelected: function () {
                let sc = this.$store.getters.getSelectedCars;
                return sc.indexOf(this.activeCar)!==-1;
            },
            selected:function(){
                return this.$route.name=="car_show_selected";
            },
            allowSlide: function(){
                return this.cars && this.cars.length>1;
            },

            cars:function (){
                if (this.selected) {
                    return this.selectedcars;
                } else {
                    return this.$store.getters.getCars;
                }
            },

            activeCar:function (){                                              //aktuální ID vozidla
                    return this.$store.getters.getActiveCar;
            },

            activeCarIndex: function(){
                if (this.cars) {
                    return this.cars.findIndex(c=>c.id==this.activeCar);
                }
            },



            carLeft:function() {
                if (this.cars) {
                    if (this.activeCarIndex>0) {
                            return this.cars[this.activeCarIndex-1];
                    }
                }
            },
            carRight:function() {
                if (this.cars) {
                    if (this.activeCarIndex!==-1 && this.activeCarIndex<this.cars.length) {
                            return this.cars[this.activeCarIndex+1];
                    }
                }
            }


        },
        watch:{
            activeCar: function () {
                this.loadCar();
            }
        },
        mounted: function() {
            //nastaví první kategorii jako aktivní
            let panel = this.getValue('rightPanel','UP_');
            console.log('PANEL' +panel, this.carsPanel);
            if (panel != this.carsPanel) {
                this.togglePanel();
            };
            if (this.$route.params.car_id>0){
                this.car_id = this.$route.params.car_id;
                this.$store.commit('setActiveCar', this.$route.params.car_id);
            }
            if (this.$route.params.demand_id>0){
                            this.demand_id = this.$route.params.demand_id;
            }            
            this.loadCar();
        },

        methods: {
            togglePanel: function() {
                this.$store.commit('toggleCarsPanel');
                this.storeValue('rightPanel','UP_', this.carsPanel);
            },

            //zda je tlačítko vybráno

            //nastaví jý vůz
            selectCar:function(id){
                console.log('ACTIVE CAR', id);
                this.$store.commit('setActiveCar', id);
            },

            selectedCar: function(id) {
                this.$store.commit('changeSelectedCars', id);  
            },
            
            goTo: function(direction) {                
                let c=null;
                if (direction<0) {
                    c = this.carLeft;
                } else {
                    c = this.carRight;
                }
                if (c) {
                    console.log('NEW ACTIVE',c);

                    this.$store.commit('setActiveCar', c.id);
                    this.$router.replace({ name: this.selected?'car_show_selected':'car_show', params: { car_id: c.id }});
                }
            },

            loadCar:function (id = null){
                if (id===null) {
                    id = this.activeCar;
                }
                this.fetchData('load_car',id);
                this.$store.commit('setActiveCar', id);
            },




            //přines data z DB
            fetchData:function(command, data) {
                this.startLoading=true;


                let inputData = {command:command, api_id:'demandCarApi'};
                let results = {   apiData:'data', successApi:'success', errors:'errors', };

                switch (command) {
                    case 'load_car':
                            inputData.car_id = data;       
                            inputData.selected_cars = this.$store.getters.getSelectedCars;
                            results.car = "car";
                            results.selectedcars = "selectedcars";
                        break;
                
                    default:
                        break;
                }
                console.log('CHCI CAR', inputData);
                var d = this.getApiData( 
                            inputData,
                            results
                );

            },

        },
        



    }
        

</script>


<style>
    .main-container{
        display: flex;
        flex-direction: row;  
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        min-height: 50em;
    }

    #top{
        align-self: flex-start;
        align-self: stretch;
        flex-grow: 1;
    }

    .c-cars {
        background-color: aqua;
    }
    .c-cars-active{
        background-color: #fff;
        transition: .4s;
    }
    .c-cars-selected {
        background-color: green;
        color: brown;
    }

    .title-img {
        width: 100%;
    }
    .cars-panel {
        width: 15rem;
        margin-left:2rem;
        align-self: stretch;
        padding:0px;

        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        border: rgb(196, 196, 196)  1px solid;
        border-radius: 3px;
        background-color: #fff;
        overflow: hidden;
        transition: width .5s ease-in-out;
    }
    .cars-panel-open {
        width: 50rem;
        transition: width .5s ease-in-out;

    }
    .list-cars-img {
        float:left;
    }    

    .list-cars-img img{
        width:5rem;
    }    

    .cars-panel-open .list-cars-img img{
        width:7rem;
    }    

    .list-cars-title{
        font-size: .7rem;
        max-height: 4rem;
        padding-left: 3px;
        overflow:hidden;
    }

    .cars-panel-open .list-cars-title{
        font-size: 1rem;
    }




    .list-cars{
        display: inline-block;
        width:100%;
        border:2px white solid;

    }


    .list-cars-active{
        background-color: rgb(201, 201, 201);
        border: 2px solid blue;

    }

    .car-card{
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        border: rgb(196, 196, 196)  1px solid;
        padding: 2rem;
        background-color: #fff;
        border-radius: 3px;
        flex-grow: 1;

    }

    .car-card-inner{
        align-items: flex-start;
        background-color: #fff;
        flex: 1; 
    }


    .item-prop{
        color:green;
    }
    .item-prop-desc{
        color:silver;
    }

    .slide {
        color:grey;
        font-size: 2rem;
        margin:1rem;
        cursor: pointer;

    }
    .slide:hover{
        color: green;
        transition: transform .2s ease;
        transform: scale(1.2);
    }
    .icon-size {
        font-size:1.4rem;
        display: inline-block;
        padding: 7px;
        border-radius: 3px;
 margin: auto;        
    }

    .icon-size:hover {
        background-color: white;
        transition: .4s background-color ease;
    }

    .btn-new {
        font-size:1.1rem;
        display: inline-block;
        padding-top:0px;
 margin: auto;        
        padding-bottom: 1px;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 1rem;
        margin:3px;
        height: 2.5rem;
        line-height: 2.5rem;
        transition: .4s background-color ease;
    }

    .btn-new:hover {
        background-color: white;
        transition: .4s background-color ease;
    }


    .photo{
        max-width: 10rem;
        border: 1px green;
        display: inline-block;
    }

</style>
