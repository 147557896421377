import { render, staticRenderFns } from "./CheckboxComponent.vue?vue&type=template&id=ec1e4bde&scoped=true"
import script from "./CheckboxComponent.vue?vue&type=script&lang=js"
export * from "./CheckboxComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec1e4bde",
  null
  
)

/* custom blocks */
import block0 from "./CheckboxComponent.vue?vue&type=custom&index=0&blockType=CheckBox&label=Foo&value=foo&v-model=MySelectedValues"
if (typeof block0 === 'function') block0(component)
import block1 from "./CheckboxComponent.vue?vue&type=custom&index=1&blockType=CheckBox&label=Bar&value=bar&v-model=MySelectedValues"
if (typeof block1 === 'function') block1(component)
import block2 from "./CheckboxComponent.vue?vue&type=custom&index=2&blockType=CheckBox&label=Baz&value=baz&v-model=MySelectedValues"
if (typeof block2 === 'function') block2(component)

export default component.exports