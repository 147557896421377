<template>
    <div>

        <input type="file" class="" id="customFile" ref="file">
        <input-component label="Název dokumentu" v-model="file_title" id="file_title" name="file_title" type="text">
        </input-component>

        <button @click = "uploadFile">Nahrát</button>
    </div>

</template>
<script>
import InputComponent from './InputComponent.vue';
export default {
    components: {
        InputComponent,
    },
    props: {
        demand:Number, //id poptávky
        title:String, //nadpis
        type:Number, //typ - 8 = demand
    },
    data: function() {
        return {
                file:null,  //pro dokument k uploadu
                file_title:"",              
        }
    },
    methods: {

        getApiData: function (inputFields, variableName = 'apiData', finishedClosure = ()=>{}) {
    
            this.$store.dispatch('startLoading');

            axios.defaults.withCredentials = true;
            axios.post('/api/request', inputFields)
                    .then(response => {
                        
                        // console.log('VRACENA DATA',response.data);
                        //console.log(response.data.message);
                        //console.log(response);
                        // console.log('RESPONSE (global.js): ',response.data);
                        if (variableName>"") {
                            if (typeof variableName === 'string') {
                                this[variableName] = response.data;
                            } else if (typeof variableName === 'object') {
                                if (Object.keys(variableName)!==undefined) {
                                    //pro všechny položky 
                                    for (var key of Object.keys(variableName)) {

                                        if (variableName[key]==='data') {
                                            this[key] = response.data;
                                        } else if (variableName[key]==='success') {                                           
                                            this[key] = response.data.success;
                                        } else if (variableName[key]==='errors') {
                                            this[key] = response.data.errors;
                                        } else {
                                            this[key] = response.data.data[variableName[key]];
                                        }
                                    }
                                    //získaný process ID uloží
                                    console.log('GLOBAL - VRACENA DATA',response.data);
                                }
                            }
                        } 
                        finishedClosure();
                        this.$store.dispatch('finishLoading');
                    })
                    .catch(error => {
                        console.log(error.response);
                        finishedClosure();
                        this.$store.dispatch('finishLoading');
                    });
                    

        },

        uploadFile: function(){
            //pokud file

        },
    },
    computed: {

    },
}
</script>