<template>
    <div @click.stop="">
                    <!-- POLE hledání zákazníka -->
                    <div>
                                <input
                                    id="query"
                                    ref="query"
                                    v-model="query"
                                    type="string"
                                    name="query"
                                    @click.stop=""
                                    @submit.stop="findCustomers"
                                    @keyup="findCustomers"
                                    autofocus
                                    autocomplete="off"
                                    readonly onfocus="this.removeAttribute('readonly');"
                                >
                    </div>
                    <!-- ¸Výsledky hledání  -->

                        <div class="c-cust-list" v-if="foundCustomers">
                            <div title="ESC - zavřít/otevřít" class="float-right" @click="foundCustomers=0"><i class="fas fa-window-close icon-size"></i></div>
                            <div class="alert-info">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                            <div class="btn-new" @click="newCustomer">
                                                <i class="fas fa-plus-circle icon-size"></i>
                                                Nový zákazník
                                            </div>
                                            <span title="Šipkami nahoru/dolu + ENTER vyberete zákazníka">

                                            <i class="far fa-arrow-alt-circle-down icon-size" @click="activeCustomerDown"></i>
                                            <i class="far fa-arrow-alt-circle-up icon-size" @click="activeCustomerUp"></i>
                                            </span>
                                    </div>
                                    <div>
                                        <label for="bus-only" v-if="!privateOnly">Firmy&nbsp;<input type="checkbox" name="bus-only" id="bus-only" v-model="busOnly" @change="findCustomers(false)"></label>
                                        <label for="priv-only" v-if="!busOnly">Soukr&nbsp;<input type="checkbox" name="priv-only" id="priv-only" v-model="privateOnly" @change="findCustomers(false)"></label>
                        
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-for="(c,key) in customers" v-bind:key="c.id" @click="sendCustomer(key)" @mouseover="activeCustomer=key">
                                <div class="col-12">
                                    <div class="c-cust-list-item" :class="{'active':key==activeCustomer}">
                                        <div class="float-right">
                                            <span v-if="c.type==2 && c.ic>''">
                                                IČ {{ c.ic }}
                                            </span>
                                            <span v-if="c.type==1 && c.rc>''">
                                                RČ {{ c.rc }}
                                            </span>
                                            <span class="badge badge-pill badge-light">{{ c.shortcut }}</span>
                                        </div>
                                        <i class="fas fa-user-lock" v-if="c.type==1"></i>
                                        <i class="far fa-building" v-if="c.type==2"></i>

                                        <span v-if="c.type==2">{{ c.title }} <span class="badge badge-pill badge-light">{{ c.name }} {{ c.firstname }}</span> </span>
                                        <span v-if="c.type==1">{{ c.name }} {{ c.firstname }}</span>
                                        <br>
                                        {{ c.street }} {{ c.city }} {{ c.psc }}
                                    </div>
                                </div>

                            </div>
                        </div>              

    </div>


</template>
<script>
export default {
    params: {
    },
    data: function(){
        return {
            query:'',
            lastQuery:"",
            foundCustomers:0,
            customers: [],
            busOnly:false,  //zda jen firmy nebo i ...
            privateOnly:false,  //zda jen firmy nebo i ...
            activeCustomer:0,
        }
    },
    computed: {
        
    },
    watch:{
        customers: function(){
            this.foundCustomers = 1;
                this.$emit('search-start');

        },
        query:function() {
            this.ic = this.removeChars(this.query);
        },        
    },
    methods:{

            removeChars: function (s) {
                //let d = "([A-Z])\w+";
                if (s>"") {
                    return s.replace(/[^0-9]+/, '');
                }
            },
            activeCustomerUp:function(){
                    if (this.activeCustomer>0) {
                        this.activeCustomer--;
                    }
            },
            activeCustomerDown:function(){
                    if (this.activeCustomer<this.customers.length) {
                        this.activeCustomer++;
                    }
            },



            listen:function(event) {
                console.log('list',event.keyCode);
                switch (event.keyCode) {
                    case 38:
                    this.activeCustomerUp();
                    return true;
                    break;
                    case 40:
                    this.activeCustomerDown();
                    return true;
                    break;
                    case 13: //ENTER
                    if(typeof this.customers[this.activeCustomer] === 'undefined') {} else {
                        this.sendCustomer();
                    }
                    return true;

                    case 27: //ESC
                        this.foundCustomers=(this.foundCustomers==0)?1:0;
                        return true;
                    break;
                }
            },




            //vyhledá zákoše podle pole customerName
            findCustomers: function(event) {
                //pokud delší než 2 znaky a není stejné jako naposled
                if (! this.listen(event)) {

                    console.log(event);
                    if (this.query && this.query.length>2 && (this.query.trim()!==this.lastQuery.trim())) {
                        
                        //vyhledá zákoše podle pole customerName
                        this.fetchData('s-cust', this.query);
                        this.lastQuery = this.query;

                        //this.customers = [];

                        //zobrazí okno se seznamem zákazníků
                        this.foundCustomers = 1;
                    }
                    }
            },


            //přines data z DB
            fetchData:function(command, data) {


                let inputData = {command:command, api_id:'demandCustomerApi'};
                let results = {   apiData:'data', successApi:'success', errors:'errors', };

                switch (command) {

                    case 's-cust': //zákazníci
                            inputData.query = data;        
                            inputData.busonly = this.busOnly?2:(this.privateOnly?1:0);
                            results.customers = "customers";
                            this.activeCustomer=0;

                        break;
                
                    default:
                        break;
                }

                var d = this.getApiData( 
                            inputData,
                            results
                );

            },
            //vyčistí data a založí nového zákazníka
            newCustomer: function () {
                this.$emit('new-customer');
                this.foundCustomers = 0;
            },

            //odešle data vybraného zákazníka
            sendCustomer: function (indexId = null) {
                if (indexId>0) {
                    this.activeCustomer = indexId;
                }
                //let obj = this.customers.find(o => o.id === customerId);
                this.$emit('selected-customer', this.customers[this.activeCustomer]);
                console.log('ZAVIRAM');
                this.foundCustomers = 0;
                this.$refs.query.focus()
            },




    }

}
</script>

<style scoped>

#query {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
     border-radius: 7px;
     border: 1px solid rgb(187, 187, 187);
     color: #a0d18c;
     width: 17em;
     height: 1.8em;
     font-size: 1.1rem;
     padding-left: 10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
#query:focus {
     outline: none;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    transition: box-shadow 1s ease-in-out;
}


    .c-cust-list {
        background-color: white;
        border: 2px black solid;
        right:0;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        position:absolute;
        top:3rem;
        z-index:5000;
        min-width: 50%;        
        cursor: pointer;
    }
    .c-cust-list-item {
        color: white;

        margin: 1px;
        background-color: cadetblue;
        text-decoration-style: none;
        padding: 3px;
        display: block;
        border: 1px white solid;
        min-height: 3em;
    }


    .active {
        background-color: #fff;
        color: cadetblue;
    }
</style>