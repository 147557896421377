<template>
    <div>


        <demand-panel-component id="1" :open="open" :active="isActiveTab" @active-tab="$emit('active-tab')" @toggle-tab="$emit('toggle-tab',1)">

                    <div slot="title">
                        <h2 class="d-inline-block"><i class="far fa-id-card m-2"></i>
                            Zákazník&nbsp;&nbsp;
                            <span v-if="customer_type==1">{{ customer_name}}  {{ customer_firstname }}</span>
                            <span v-if="customer_type==2">{{ customer_title>""?customer_title:"Zadejte název firmy" }}</span>

                        </h2>

                    </div>
                    <div slot = "title" class="bounce-arrow d-none d-lg-flex justify-content-end text-white flex-grow-1 pr-4"  style="font-size:1rem;padding-left:20rem;grow:1;">
                        <span v-if="showBounce">

                            Vyhledejte zákazníka <i class="fas fa-arrow-right pl-2" style="font-size:1.5rem;font-weight:700;color:yellow;"></i>
                        </span>
                    </div>
                    <div slot = "title">
                        <demand-customer-search
                                    @selected-customer="setCustomer"
                                    @new-customer="newCustomer" 
                                    @search-start="stopBounceEvent" />
                    </div>



            <div slot="content">   <!--  CONTENT-->

        
                    <div class="c-cust-field-head" @click="folderActive=(folderActive==1?2:1)"> <i :class="{'fas fa-angle-down':folderActive==1, 'fas fa-angle-right':folderActive!=1}"></i> Informace o zákazníkovi
                    </div>
                    <div v-show="folderActive==1">
                        <div class="row mt-5">
                            <div class="col-md-1">
                                        <input-component label="Firma" v-model="customer_type" id="customer_type2" name="customer_type" type="radio" value=2 true-value=2 @change="sendCustomerData">
                                        </input-component>

                            </div>

                            <div class="col-md-3" v-show="customer_type==2">
                                        <input-component label="Rámcová smlouva" v-model="customer_framecontract" id="customer_framecontract" name="customer_framecontract" type="switch" value=1 :class="{'text-danger':customer_framecontract==0, 'text-success':customer_framecontract==1}" @change="sendCustomerData">
                                        </input-component>

                            </div>

                            <div class="col-md-3" v-show="customer_type==2">
                                <div class="row">
                                        <input-component label="IČ" placeholder="IČ" name="ic" v-model="customer_ic" invalid-message="Email není zadán" type="number" @change="sendCustomerData">
                                                <div  slot="append" class="input-group-append" v-if="customer_type==2 && customer_ic>'' && customer_ic.length>4">
                                                    <button class="btn btn-outline-secondary" type="button" @click="loadFromAresIc(customer_ic)">ARES</button>
                                                </div>

                                        </input-component>
                                </div>

                            </div>

                            <div class="col-md-5" v-show="customer_type==2">
                                <input-component label="Název firmy" placeholder="Název firmy" name="title" v-model="customer_title" invalid-message="Email není zadán" type="text" @change="sendCustomerData">
                                </input-component>
                            </div>


                        </div>







                        <div class="row">
                            <div class="col-md-1">
                                        <input-component label="Osoba" v-model="customer_type" id="customer_type1" name="customer_type" type="radio" value=1 true-value=1 @change="sendCustomerData">
                                        </input-component>
                            </div>

                            <div class="col-md-1">
                                        <input-component label="Pan" v-model="customer_gender" id="customer_gender2" name="customer_gender" type="radio" value=1 true-value=1 @change="sendCustomerData">
                                        </input-component>
                                        <input-component label="Paní" v-model="customer_gender" id="customer_gender1" name="customer_gender" type="radio" value=2 true-value=2 @change="sendCustomerData">
                                        </input-component>
                            </div>

                            <div class="col-md-2">
                                <input-component label="Jméno" placeholder="Jméno" name="firstname" v-model="customer_firstname" :invalid="!customer_firstname>''" invalid-message="Chyba" type="text" @change="sendCustomerData">
                                </input-component>

                            </div>
                            <div class="col-md-4">
                                <input-component label="Příjmení" placeholder="Příjmení" v-model="customer_name" name="name" :invalid="!customer_name>''" invalid-message="Chyba" type="text" @change="sendCustomerData">
                                </input-component>

                            </div>

                            <div class="col-md-2">
                                <input-component label="RČ" placeholder="rodné číslo" name="rc" v-model="customer_rc" type="number" @change="sendCustomerData">
                                </input-component>

                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <strong>Kontakt</strong>
                            </div>


                            <div class="col-md-5">
                                <input-component label="Email" placeholder="Email zákazníka" name="email" v-model="customer_email" :invalid="!validEmail(customer_email)" invalid-message="Email není zadán" type="email" @change="sendCustomerData">
                                </input-component>
                            </div>
                            <div class="col-md-3">
                                <input-component label="Tel." placeholder="Telefon" name="phone" v-model="customer_phone" :invalid="!validPhone(customer_phone)" invalid-message="Telefon není zadán" type="text" @change="sendCustomerData">
                                </input-component>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-md-1">
                                <strong>Adresa</strong>
                            </div>
                            <div class="col-md-4">
                                <input-component label="Ulice" placeholder="" name="street" v-model="customer_street" invalid-message="Ulice není zadán" type="text" @change="sendCustomerData">
                                </input-component>
                            </div>
                            <div class="col-md-3">
                                <input-component label="Město" placeholder="" name="city" v-model="customer_city" invalid-message="Město není zadán" type="text" @change="sendCustomerData">
                                </input-component>

                            </div>

                            <div class="col-md-2">
                                <input-component label="PSČ" placeholder="" name="psc" v-model="customer_psc" :invalid="customerDataMissing('customer_psc', )" invalid-message="PSČ není zadán" type="number" @change="sendCustomerData">
                                </input-component>

                            </div>
                        </div>
                            <div class="float-right">
                                <transition name="button-fade">
                                    <div class="btn-new" @click="$emit('save-customer')" v-if="canSaveCustomer">
                                        <i class="far fa-save icon-size"></i>Uložit zákazníka
                                    </div>
                                </transition>
                            </div>
                        <div class="row mb-2">
                            <div class="col-md-4">
                                        <input-component label="Souhlas se zasláním emailu" v-model="customer_agreement" id="customer_agreement" name="customer_agreement" type="switch" value=1 :class="{'text-danger':customer_agreement==0, 'text-success':customer_agreement==1}" @change="sendCustomerData">
                                        </input-component>
                            <br>
                            </div>

                            <div class="col-md-5" v-show="contactsCount>1 && customerData.id>0">
                                <input-component label="Kontakt" placeholder="" name="contact_id" v-model="customer_contact_id" :items="contacts" invalid-message="Vyberte kontakt" type="select" @change="sendCustomerData">
                                </input-component>
                            </div>                            



                        <!-- <div class="row">
                            <div class="col text-right">                                
                                <div class="p-1 px-2">
                                    <div class="text-warning d-inline-block" v-if="this.errorMsg>''">
                                       <i class="fas fa-exclamation-triangle"></i> {{ this.errorMsg }}
                                    </div>
                                    <button class="btn btn-primary" v-if="customer_id>0 && dataChanged">Uložit údaje o zákazníkovi</button>
                                    <button class="btn btn-primary" v-if="customer_id==0 && dataChanged" :class="{'disabled btn-secondary':somethingMissing, 'btn-primary':!somethingMissing}" :title="errorMsg">Založit zákazníka</button>
                                </div>
                            </div>
                        </div> -->



                    </div>

        </div>

                    <div class="c-cust-field-head" @click="folderActive=(folderActive==2?1:2)" v-show="customer_id>0"> <i :class="{'fas fa-angle-down':folderActive==2, 'fas fa-angle-right':folderActive!=2}"></i> Historie
                    </div>
                    <div v-show="folderActive==2 && customer_id>0">



                            <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <div class="float-right btn btn-sm btn-secondary" @click="loadCustomerHistory">
                                        Načíst
                                    </div>
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Historie zákazníka
                                    </button>
                                    
                                </h2>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#customerHistory">
                                    <div class="card-body">
                                        <div class="row" v-for="h in this.history" :key="h.id">
                                            <div class="col-md-2">
                                                {{  h.type }}
                                            </div>
                                            <div class="col-md-6">
                                                {{  h.description }}
                                            </div>

                                            <div class="col-md-2">
                                                {{  h.time }}
                                            </div>

                                            <div class="col-md-2">
                                                {{  h.fullname }}
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Poptávky
                                    </button>
                                </h2>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#customerHistory">
                                <div class="card-body">
                                    <div class="row" v-for="d in this.demands" :key="d.id">
                                        <div class="col-md-2">
                                            {{  d.status }}
                                        </div>
                                        <div class="col-md-6">
                                            {{  d.title }}
                                        </div>

                                        <div class="col-md-2">
                                            {{  d.updated_at }}
                                        </div>
                                        <div class="col-md-2">
                                            <a :href="'poptavky/'+d.id">
                                                Poptávka
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Případy
                                    </button>
                                </h2>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#customerHistory">
                                <div class="card-body">
                                    <div class="row" v-for="d in this.deals" :key="d.id">
                                        <div class="col-md-2">
                                            {{  d.phase_id }}
                                        </div>
                                        <div class="col-md-6">
                                            {{  d.title }}
                                        </div>

                                        <div class="col-md-2">
                                            {{  d.updated_at }}
                                        </div>
                                        <div class="col-md-2">
                                            <a :href="'deals/'+d.id">
                                                <i class="fas fa-phone-volume"></i>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Vozidla
                                    </button>
                                </h2>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingThree" data-parent="#customerHistory">
                                <div class="card-body">
                                    <div class="row" v-for="c in this.cars" :key="c.id">
                                        <div class="col-md-2">
                                            {{  c.status }}
                                        </div>
                                        <div class="col-md-6">
                                            {{  c.title }}
                                        </div>

                                        <div class="col-md-2">
                                            {{  c.updated_at }}
                                        </div>
                                        <div class="col-md-2">
                                            <a :href="'cars/'+c.id">
                                                <i class="fas fa-car"></i>
                                            </a>
                                        </div>

                                    </div>


                                </div>
                                </div>
                            </div>
                            </div>



                    </div>

            </div> <!-- /CONTENT -->
        </demand-panel-component>


    </div>
</template>



<script>
import DemandPanelComponent from './DemandPanelComponent.vue';
import DemandCustomerSearch from './DemandCustomerSearch.vue';
import CheckboxComponent from './CheckboxComponent.vue';
import InputComponent from './InputComponent.vue';

export default {
    components: { InputComponent, DemandCustomerSearch, DemandPanelComponent },

    props: {
          customerData:{},
          loadCustomer:null,
          contacts:{},
          rating:0,
          open:false,
            isActiveTab:false,
    },
    data: function() {
          return {
              
                activeCar:0,
                folderActive:1,
                query:"",
                customer: {}, //všechna pole zákazníka

                demands:[],
                cars:[],
                history:[],
                deals:[],
                stopBounce: false,

                customer_name:"",
                customer_firstname:"",
                customer_psc:"",
                customer_ic:"",
                customer_title:"",
                customer_rc:"",
                customer_email:"",
                customer_phone:"",
                customer_city:"",
                customer_street:"",
                customer_type:1,
                customer_id:"",
                customer_contact_id:0,
                customer_agreement:0,
                customer_framecontract:0,
                customer_country_id:196,
                customer_gender:"",

                errorMsg:"", //chybová hláška

                
                loadedCustomer:{},  //načtený zákazník z databáze
                customerARES:null,
                ic:null,
                dataChanged:false,

        }
      },

        watch:{
            // customer:function(){
            //     this.$emit('customer-update',this.customer);                
            // },
            customerData:function() {
                //console.log('MOUNTED WATCH - custData COMPONENT', this.customerData);                
                console.log('CUST DATA - WATCH', this.customerData);                

                this.object2flat(this.customerFlat2object, 'customer_',this.customerData);

            },

            loadCustomer: function() {
                console.log('LOADCUSTOMER', this.loadCustomer);
                if (this.loadCustomer>0) {
                    this.fetchData('load_customer',this.loadCustomer);
                }
    },
            customerARES: function () {
                //při změně to načte do položek    
                //polozky customerARES - type, title, ic, rc, street, name, firstname, psc, city, phone, email
                if (this.customerARES) {                   
                    if(this.customer_ic!=this.customerARES.ico) {  //naní to jen aktualizace
                        this.cleanCustomer();

                    } 
                    // this.$set(this.customer, 'id', null); //na založení
                    // this.$set(this.customer, 'title', this.customerARES.name);
                    // this.$set(this.customer, 'street', this.customerARES.street);
                    // this.$set(this.customer, 'city', this.customerARES.town);
                    // this.$set(this.customer, 'psc', this.customerARES.zip);
                    // this.$set(this.customer, 'ic', this.customerARES.ico);
                    // this.$set(this.customer, 'type', 2);

                    this.customer_title=this.customerARES.name;
                    this.customer_street=this.customerARES.street;
                    this.customer_city=this.customerARES.town;
                    this.customer_psc=this.customerARES.zip;
                    this.customer_ic=this.customerARES.ico;
                    this.customer_type=2;
                    // console.log('ARES DATA', this.customer_title);
                    // console.log('ARES DATA Loa', this.customerARES.name);
                    
                    this.sendCustomerData();
                }
            },
            loadedCustomer: function () {
                //při změně to načte do položek    
                //polozky customerARES - type, title, ic, rc, street, name, firstname, psc, city, phone, email
                if (this.loadedCustomer) {  
                    console.log('LOADED CUSTOMER (watch)', this.loadedCustomer); 
                    this.cleanCustomer();
                    this.object2flat(this.customerFlat2object, 'customer_',this.loadedCustomer);

                    //aktualizace do mateřské komponetny
                    this.sendCustomerData();
                    this.dataChanged = false;
                }            
            }
    

        },

        mounted: function() {
            console.log('MOUNTED', this.loadCustomer);

        },
        computed: {
            contactsCount: function() {
                if (this.contacts) {
                    return ( Object.keys(this.contacts).length);
                } else {
                    return 0;
                }
            },
            somethingMissing:function(){
                return this.customerDataMissing();
            },

            hideSearchBox: function() {
                return (this.customer_id>0);
            },
            showBounce: function() {
                return !(this.customer_id>0 || this.demand_id>0 || this.stopBounce || this.customer_name>"" || this.customer_title>"" || this.customer_ic || this.customer_firstname>"");
            },

            canCreateCustomer: function() {
                return this.customer.id>0;
            },
            canSaveCustomer:function() {
                return this.dataChanged && (this.customer_id>0 || this.customer_name>"" || this.customer_title>"" || this.customer_ic || this.customer_firstname>"");
            }

        },
        methods: {

            stopBounceEvent:function(){
                this.stopBounce = true;
            },
            validDataField:function(name, col) {
                
                let f = col['fun'];
                let g = col['global'];
                let p = col['params'];
                // console.log('VALID DATA FIELD' + name + ' : ' + f,col);
                if (g) {
                    return window[f](this[name],...p);
                } else {
                    return this[f](this[name],...p); //...p
                }
            },
            
            /**
             * true pokud není v pořádku - buď konkrétní položka či všechny v seznamu 
             * @param errorVar - kam pošle chbovou hlášku (v rámci komponenty)
             */
            customerDataMissing: function (column = null, errorVar = 'errorMsg', errorPrefix='Zkontrolujte prosím ', cols = null) {
                console.log('SPUSTENA KONTROLA',column + ' -----------------------------------------------------');
                cols = {
                    customer_name:{fun:'validStringNotEmptyMaxSize', global:false, params:[3,50], msg:'Přímení'}, //fukce ke spuštění, jestli v $this nebo ne a params další parametry jsou-li, msg - chybová hláška do 
                    customer_firstname:{fun:'validStringNotEmptyMaxSize', global:false, params:[2,20], msg:'Křestní jméno'}, 
                    customer_email:{fun:'validEmail', global:false, msg:'Email', params:[]},
                    customer_phone:{fun:'validPhone', global:false, msg:'Telefonní číslo', params:[]},
                    customer_psc:{fun:'validStringNotEmptyMaxSize', global:false, params:[5,6], msg:'PSČ'}, 
                };
                //projde všechny nebo jen jednu
                let msg = "";
                if (column === null) {
                    for (var name in cols) {
                        if (cols.hasOwnProperty(name)) {
                            const col = cols[name];
                            console.log('KONTROLA' + name, col);
                            if (!this.validDataField(name, col)) {
                                msg += col['msg']+' ';
                            }
                        }
                    }
                } else {
                        if (!this.validDataField(column, cols[column])) {
                            msg += cols[column]['msg'];
                        }
                }
                if(msg>"") {
                    console.log('Vysledek:',errorPrefix + msg);
                    this[errorVar] = errorPrefix + msg;
                    return true;
                }
                this[errorVar] = "";
                return false;
            },


            cleanCustomer: function() {
                this.customer={id:0};
                this.object2flat(this.customerFlat2object,'customer_',this.customer);
                this.dataChanged = false;

                    // this.customer_id = "";
                    // this.customer_name = "";
                    // this.customer_street = "";
                    // this.customer_email = "";
                    // this.customer_city = "";
                    // this.customer_psc = "";
                    // this.customer_ic = "";
                    // this.customer_phone = '';
                    // this.customer_type = 2;
                    // this.customer_email = '';

            },
            //vyčistí data a založí nového zákazníka
            newCustomer: function () {
                this.cleanCustomer();
            },



            loadFromAresIc: function (ic) {
                
                this.ic = ic;
                return this.loadFromAres();
            },


            loadFromAres: function () {
                if (this.ic>"") {
                    this.fetchData('ares',this.ic);
                }
            },
            loadCustomerHistory: function () {
                    this.fetchData('load_customer_history',this.customer_id);
            },

            //vyplní pole zákazníka
            setCustomer: function (customer) {

                if (customer&&customer.id>0) {
                    this.fetchData('load_customer',customer.id);
                }
                this.customer_name = customer.title;
                this.customer_phone = customer.phone;
                this.customer_email = customer.email;

            },

            //emituje data zákazníka na rodiče k dlašímu zpracování
            sendCustomerData(){
                console.log('SEND CUSTOMER DATA - customer dem compon');
                this.dataChanged = true;
                this.$emit('customer-update',this.flat2object(this.customerFlat2object,'customer_', this));
            },

            //přines data z DB
            fetchData:function(command, data) {


                let inputData = {command:command, api_id:'demandCustomerApi'};
                let results = {   apiData:'data', successApi:'success', errors:'errors', };

                switch (command) {
                    case 'ares':
                            inputData.api_id = 'aresApi';
                            inputData.ico = data; 
                            inputData.command = 'find'; 
                            results.customerARES = "customerARES";

                            console.log('ARES', inputData);
                    break;
                    case 'load_customer':
                            inputData.customer_id = data;        
                            results.loadedCustomer = "loadedCustomer";
                            results.history = "history";
                            results.deals = "deals";
                            results.demands = "demands";
                            results.cars = "cars";
                            this.deals=[];
                            this.demands=[];
                            this.cars=[];
                            this.history=[];
                        break;
                    case 'load_customer_history':
                            inputData.customer_id = data;        
                            results.history = "history";
                            results.deals = "deals";
                            results.demands = "demands";
                            results.cars = "cars";
                            this.deals=[];
                            this.demands=[];
                            this.cars=[];
                            this.history=[];
                        break;
                    // case 's-cust': //zákazníci
                    //         inputData.query = data;        
                    //         inputData.busonly = this.busOnly?2:(this.privateOnly?1:0);
                    //         results.customers = "customers";
                    //         this.activeCustomer=0;

                    //     break;
                
                    default:
                        break;
                }
                
                console.log('GUIDECOMP - NACITAM DATA', inputData);
                console.log('GUIDECOMP - NACITAM DATA', results);

                var d = this.getApiData( 
                            inputData,
                            results
                );

            },

        },
        

    }
</script>


<style>

    .c-cust-field-head {
        background-color: silver;
        font-size: .9rem;
        color: rgb(92, 92, 92);
        line-height: 2rem;
    }
    .c-cust-field {
        color: black;
    }
    .c-cust-field > label {
        color: blue;
        font-weight: 600;
    }
    .c-cust-mystery {
        color: black;
        background-color: orange;
        font-size: .8rem;
    }
    .c-hide {
        display: none;
    }

    .icon-size {
        font-size:1.4rem;
        display: inline-block;
        padding: 7px;
        border-radius: 3px;
    }

    .icon-size:hover {
        background-color: white;
        transition: .4s background-color ease;
    }

    .btn-new {
        font-size:1.1rem;
        display: inline-block;
        padding-top:0px;
        padding-bottom: 1px;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 1rem;
        margin:3px;
        transition: .4s background-color ease;
        border: 2px solid rgb(219, 219, 219);
    }

    .btn-new:hover {
        transition: .4s background-color ease;
    }



.bounce-arrow {
    animation: bounce 2s 3;  
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
	40% {
    transform: translateX(-30px);
  }
	60% {
    transform: translateX(-15px);
  }
}

.button-fade-enter-active,
.button-fade-leave-active {
  transition: opacity 1s ease;
}

.button-fade-enter-from,
.button-fade-leave-to {
  opacity: 0;
}
</style>
