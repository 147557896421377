<template>
    <div>
        <canvas :id="'pie-chart' + this.historyindex" width="100%" height="100%"></canvas>
    </div>
</template>



<script>
// import mixin from 'mixin.js';

    export default {
      props: {
        historyindex:null,
        statPivots:Array,       
        statFuns:Array, 
        stat:Object,
        pivots:Object,        
        average:false, // zda je přepnuto na průměrnou cenu
        selectedColumn:String,
       },

    data: function() {
        return {
            graphData:{},   //data pro graf
            chart:[],
            countColumn:'count', //dle statFuns
            //barvy pro jednotlivé linie
            borderColors:[
                "blue","rgb(179, 0, 31)","purple", "#3e95cd","#8e5ea2","#3cba9f","#e8c3b9","#c45850",'green','orange','black','brown','silver'
            ],
            graphSize:2,    //velikost grafu, 1 = 30, 2 = 50, 3=80, 0 = hide
        };
    },

    computed: {


    }, //computed

    methods: {

        setGraphSize:function(size) {
            if (size>-1 && size<4) {
                this.graphSize = size;
            }
        },



        getPivotData: function(pivots) {
            const keys  = Object.keys(pivots)
            var data=({
                    data:[],
                    backgroundColor:this.borderColors,
                });
                const d = [];
                const isAverageAvailable = this.averageAvailabale();

                for (const pivot of keys) {
                    if (this.stat[0][pivot]=== undefined) {
                        // data[i]['data'][pivot] = 0;
                        d.push(0);
                    } else {
                        
                        if (isAverageAvailable) {
                            if (this.stat[0][pivot]['count']>0) {
                                d.push(Math.round(this.stat[0][pivot][this.countColumn]/this.stat[0][pivot]['count']));

                            } else {
                                d.push(0);
                            }

                        } else {
                            d.push(this.stat[0][pivot][this.countColumn]);
                        }
                    }

                    data['data'] = d;

                    // console.log(h.stat[0][pivot]['count']);
                }

            // console.log('data');
            // console.log(data);
            return [data];
        },



        findStatFun: function(funId) {
            for (const fun of this.statFuns) {
                if (funId===fun.id) {
                    return fun;
                }
            }
        },

        //pro potřebu koláčového grafu - zda vybarná funkce má "nárok na průměr", či nemá znemožněný součet
        averageAvailabale: function() {
          //vezme si zvolený sloupec a zjistí, jestli je valid average
          //musí projít všechny 

                const fun = this.findStatFun(this.countColumn);
                if (fun) {
                        // console.log((this.average&&('count'!=fun.id)&&!fun.no_average) || fun.no_sum);
                        return (this.average&&('count'!=fun.id)&&!fun.no_average) || fun.no_sum;
                    } else {
                        return this.average;
                    }
          },



        showGraph: function() {

            if (this.history===null) {return;}

                let pivots = this.pivots;
                let datasets = this.getPivotData(pivots);
                // console.log ('SHOW graph');
                // console.log (pivots);
                // console.log (datasets);
                if (this.chart[this.historyindex] !== undefined && this.chart[this.historyindex] !== null) {
                    this.chart[this.historyindex].destroy();
                }
                //načti všechna data do array
                this.chart[this.historyindex] =  new Chart(document.getElementById("pie-chart"+this.historyindex), {
                  type: 'pie',
                  data: {
                    labels: Object.values(pivots),
                    datasets: datasets,
                    //title:'xxx',

                  },
                  options: {
                    title: {
                      display: true,
                      text: this.getStatFuncName,
                    },
                    plugins: {
                        datalabels: {
                            color: "black",
                            backgroundColor:"silver",
                            opacity:.7,
                            textAlign: "center",
                            font: {
                            weight: 700,
                            size:11,
                        }
                      }
                    },
                  }
                });

        },

    }, // METHODS


      created:function(){
        this.countColumn = this.selectedColumn;
        // console.log(stat);
        // console.log(stattitle);
      }, //CREATED

      mounted:function(){
        this.showGraph();
      }, //MOUNTED

      computed:{
        getStatFuncName: function() {
            for (const s of this.statFuns) {
                if (s.id==this.countColumn) {
                    return s.title;
                    break;
                }
            }
            return "nenalezeno";
        },
      },

      watch:{
        pivots: function(){
            this.showGraph();
        },
        average: function(){
            this.showGraph();
        },
        selectedColumn:function() {
            this.countColumn = this.selectedColumn;
            this.showGraph();
        },
      }, //WATCH

    }
</script>

<style>


</style>
