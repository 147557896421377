 
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

import * as ls from "./localstorage.js";

export default new Vuex.Store(
{


  state: {
        loading:true,
        drawer:true,
        history:[], // { title, id}  
        cars:[], //nalezená vozidla
        carsPanel:0,
        selectedCars:[],
        activeCar:null,
        rating:0,



  },
  mutations: {



    
    //přidá nebo odebere selected car
    changeSelectedCars(state,id) {

        let i = state.selectedCars.indexOf(id);
        //pokud v seznamu -> vyjme jej
        if (i>-1) {
            state.selectedCars.splice(i,1);
        }
        else {
            state.selectedCars.push(id);
        }  
    },
     
    //seřadí vozidla
    orderCars(state, orderinfo){
        // columnName , asc
        let order = orderinfo.order?1:-1;
        let column = orderinfo.column;
        let c = state.cars;
        console.log('BEFORE',c);
        c.sort((a, b) => {
            // Use toUpperCase() to ignore character casing
            const valA = a[column];  //.toUpperCase()
            const valB = b[column];

            let comparison = 0;
            if (valA > valB) {
              comparison = 1;
            } else if (valA < valB) {
              comparison = -1;
            }
            return comparison * order;
          })
        state.cars = c;
        console.log(orderinfo);
        console.log('AFTER:', state.cars);


    },



    //nastaví , že se načítá ...
    setLoading: (state, payload)=>{
        //zkontroluje zda existuje

        //pokud ano, nastaví nový sloupec
        state.loading = payload;
    },

    //přepne left panel
    toggleCarsPanel: (state,payload) => {
        state.carsPanel = (state.carsPanel===1)?0:1;
    },


   //nastaví seznam vozidel
    setCars: (state, payload)=>{
        //zkontroluje zda existuje

        //pokud ano, nastaví nový sloupec
        state.cars = payload;
    },
    
    //nastaví seznam vybraných vozidel
    setSelectedCars: (state, payload)=>{
        //zkontroluje zda existuje

        //pokud ano, nastaví nový sloupec
        state.selectedCars = payload;
    },

    //nastaví seznam vozidel
    setActiveCar: (state, payload)=>{
        //zkontroluje zda existuje

        //pokud ano, nastaví nový sloupec
        state.activeCar = payload;
    },

    //nastaví seznam vozidel
    setRating: (state, payload)=>{
        //zkontroluje zda existuje

        //pokud ano, nastaví nový sloupec
        state.rating = payload;
    },

    //nastaví seznam vozidel
    resetCars: (state, payload)=>{
        state.cars = [];
    },



    //přidá položku do historie
    //payload = object {type, title, url, id}
    addToHistory(state, payload) {       
        //přidá novou na konec
        state.history.push(payload);     
    },

    //vyjme položku z historie - id nebo vyhledá objekt
    removeFromHistory (state, payload) {
        let removeIndex = 0;
        if (typeof payload === Number) {
            removeIndex = payload;
        } else if (typeof payload === Object) {
            removeIndex = state.history.findIndex(item=>item.id === payload.id && item.type ===payload.type);
        }
        
        console.log('Mazu index',removeIndex);
        
        if (removeIndex>0) {
            state.history.splice(foundId,1);
        }
    },

    //inicializuje storage při spuštění aplikace
    initialiseStore(state) {
        // if (localStorage.getItem('agreedToPrivacy')) {
        //   state.agreedToPrivacy = true;
        // }
      },


    //přidá že problém byl navštíven
    addVisitedProblem: (state, problem)=>{
        var found = state.visitedProblems.indexOf(problem.id);
        if(found>-1) {
            //vyjmi jej z poslední pozice
            state.visitedProblems.splice(found, 1);
        }
        //přidat na poslední pozici
        state.visitedProblems.push(problem.id);
    },


  },

    getters: {
        getCarsPanel: function(state) {
            return state.carsPanel;
        },
        getProblemHistory: function(state) {
            return state.history.filter(item=>item.type===9);
        },

        loading: function(state) {
            return state.loading;
        },


        //vrátí seznam načtených vozidel
        getCars: function(state) {
            return state.cars;
        },

        //vrátí seznam načtených vozidel
        getRating: function(state) {
            return state.rating;
        },

        //vrátí seznam načtených vozidel
        getSelectedCars: function(state) {
            return state.selectedCars;
        },

        getActiveCar: function(state) {
            return state.activeCar;
        },

        lastVisitedProblemId: function (state) {
            if (Array.isArray(state.visitedProblems) && state.visitedProblems.length) {
                return state.visitedProblems[state.visitedProblems.length - 1];
            }
        },



    },

    actions: {




        //načítání dat
        startLoading: (state, payload)=>{
                state.commit('setLoading',true);
        },      
        //konec načítání dat
        finishLoading: (state, payload)=>{
                state.commit('setLoading',false);
        },      


    },

  strict: debug,
});