<template>
    <div @click.stop="">
                    <!-- POLE hledání zákazníka -->
                    <div>
                                <input
                                    id="carentry"
                                    ref="carentry"
                                    v-model="carentry"
                                    type="string"
                                    name="carentry"
                                    @click.stop=""
                                    @submit.stop="findCars"
                                    @keyup="findCars"
                                    autofocus
                                    autocomplete="off"
                                    readonly onfocus="this.removeAttribute('readonly');"
                                >
                    </div>
                    <!-- ¸Výsledky hledání  -->

                        <div class="c-cust-list" v-if="foundCars">
                            <div title="ESC - zavřít/otevřít" class="float-right" @click="foundCars=0"><i class="fas fa-window-close icon-size"></i></div>
                            <div class="alert-info">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                            <span title="Šipkami nahoru/dolu + ENTER vyberete zákazníka">

                                            <i class="far fa-arrow-alt-circle-down icon-size" @click="activeCarDown"></i>
                                            <i class="far fa-arrow-alt-circle-up icon-size" @click="activeCarUp"></i>
                                            </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-for="(c,key) in cars" v-bind:key="c.id" @click="sendCar(key)" @mouseover="activeCar=key">
                                <div class="col-12">
                                    <div class="c-cust-list-item d-flex align-items-center justify-content-between" :class="{'active':key==activeCar}">
                                        <div>
                                            <img :src="c.img_title" alt="" width=60 height=40 class="zoom">

                                        </div>
                                        <div class="flex-fill px-2">

                                            <div>
                                                {{ c.title }}
                                                <div class="badge badge-info" v-if="c.type!=1">NV</div>
                                                <div class="badge badge-secondary"  v-if="c.type==1">OV</div>
                                            </div>
                                            <div>
                                                {{ c.month }}/{{ c.year }} &nbsp;&nbsp;Nájezd: {{ c.odo }} km
                                            </div>

                                        </div>
                                        <div>
                                            <div v-if="c.vin>''">
                                                <div class="badge badge-light pt-1">
                                                    {{ c.vin }}
                                                </div>
                                            </div>
                                            <div v-if="c.spz>''">
                                                <div class="badge badge-light pt-1">
                                                {{ c.spz }}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>              

    </div>


</template>
<script>
export default {
    params: {
    },
    data: function(){
        return {
            carentry:'',
            lastCarentry:"",
            foundCars:0,
            cars: [],
            activeCar:0,
        }
    },
    computed: {
        
    },
    watch:{
        cars: function(){
            this.foundCars = 1;
        },
        carentry:function() {
            this.ic = this.removeChars(this.carentry);
        },        
    },
    methods:{

            removeChars: function (s) {
                //let d = "([A-Z])\w+";
                if (s>"") {
                    return s.replace(/[^0-9]+/, '');
                }
            },
            activeCarUp:function(){
                    if (this.activeCar>0) {
                        this.activeCar--;
                    }
            },
            activeCarDown:function(){
                    if (this.activeCar<this.cars.length) {
                        this.activeCar++;
                    }
            },



            listen:function(event) {
                console.log('list',event.keyCode);
                switch (event.keyCode) {
                    case 38:
                    this.activeCarUp();
                    return true;
                    break;
                    case 40:
                    this.activeCarDown();
                    return true;
                    break;
                    case 13: //ENTER
                    if(typeof this.cars[this.activeCar] === 'undefined') {} else {
                        this.sendCar();
                    }
                    return true;

                    case 27: //ESC
                        this.foundCars=(this.foundCars==0)?1:0;
                        return true;
                    break;
                }
            },




            //vyhledá zákoše podle pole carName
            findCars: function(event) {
                //pokud delší než 2 znaky a není stejné jako naposled
                if (! this.listen(event)) {

                    if (this.carentry && this.carentry.length>2 && (this.carentry.trim()!==this.lastCarentry.trim())) {
                        
                        //vyhledá zákoše podle pole carName
                        this.fetchData('search_cars', this.carentry);
                        this.lastCarentry = this.carentry;
                        this.foundCars = 1;
                    }
                }
            },


            //přines data z DB
            fetchData:function(command, data) {

                let inputData = {command:command};
                let results = {   apiData:'data', successApi:'success', errors:'errors', };
                switch (command) {
                    case 'search_cars': //fulltextové vyhledání vozidla
                            inputData.api_id = 'demandCarApi';

                            //přibalí informace o poptávce apod
                            inputData.demand_id = this.demand_id;
                            inputData.demand_description = this.demand_description;
                            inputData.customer = this.customer;
                            inputData.order_by = this.order;
                            inputData.order_asc = this.order_asc;

                            inputData.limit = 20;
                            inputData.carentry = data;

                            results.cars = "cars";
                            results.demand_errors = "errors";
                        break;

                }
                
                var d = this.getApiData( 
                            inputData,
                            results
                );

            },




            //odešle data vybraného vozu
            sendCar: function (indexId = null) {
                if (indexId>0) {
                    this.activeCar = indexId;
                }
                //let obj = this.cars.find(o => o.id === carId);
                this.$emit('selected-car', this.cars[this.activeCar]);
                this.foundCars = 0;
                this.$refs.carentry.focus()
            },




    }

}
</script>

<style scoped>

#carentry {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
     border-radius: 7px;
     border: 1px solid rgb(187, 187, 187);
     color: #a0d18c;
     width: 17em;
     height: 1.8em;
     font-size: 1.1rem;
     padding-left: 10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
#carentry:focus {
     outline: none;
    border: 1px solid rgb(136, 136, 136);
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    transition: box-shadow 1s ease-in-out;
}


    .c-cust-list {
        background-color: white;
        border: 2px black solid;
        right:0;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        position:absolute;
        top:3rem;
        z-index:5000;
        min-width: 50%;        
        cursor: pointer;
    }
    .c-cust-list-item {
        color: white;

        margin: 1px;
        background-color: cadetblue;
        text-decoration-style: none;
        padding: 3px;
        display: block;
        border: 1px white solid;
        min-height: 3em;
    }


    .active {
        background-color: #fff;
        color: cadetblue;
    }
</style>