<template>
    <div v-cloak>

        


        <!-- DATA TABLE START   *****************************************************************    -->
                    <div class="table-responsive"> 

                                <table class="simple-table" width="100%" cellspacing="0">
                                  <thead>
                                    <tr>
                                      <th width="10%" class="text-center" @click.prevent="orderBy('spz')">SPZ / VIN</th>
                                      <th width="15%" class="text-center" @click.prevent="orderBy('title')">Vůz</th>
                                    <th title="Lokalita" width="5%" class="text-right" @click.prevent="orderBy('office_id')" :class="{'text-info':this.orderColumn=='office_id'}"> 
                                        Lok
                                       
                                    </th>
                                    <th title="Lokalita" width="5%" class="text-right" @click.prevent="orderBy('typ_zakaznika')" :class="{'text-info':this.orderColumn=='typ_zakaznika'}"> 
                                        Zakazník
                                       
                                    </th>

                                      <th width="5%" @click.prevent="orderBy('dni_sklad')" :class="{'text-info':this.orderColumn=='dni_sklad'}" 
                                      title="Počet dní na skladě od přivezení vozidla">
                                        Dní skladem
                                        <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                      </th>

                                      <th width="5%" class="text-right" @click.prevent="orderBy('dni_inzerce')" :class="{'text-info':this.orderColumn=='dni_inzerce'}">
                                            Dní inzerce <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                      </th>

                                      <th width="5%" class="text-right" @click.prevent="orderBy('car_margin')" :class="{'text-info':this.orderColumn=='car_margin'}">
                                            Marže
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                      </th>

                                      <th width="5%" class="text-right" @click.prevent="orderBy('margin_percent')" :class="{'text-info':this.orderColumn=='margin_percent'}">
                                            % marže
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                      </th>


                                      <th width="5%" class="text-right" @click.prevent="orderBy('skoda_bonus')" :class="{'text-info':this.orderColumn=='skoda_bonus'}">
                                            Škoda bonus
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                      </th>

                                      <th width="9%" class="text-right" @click.prevent="orderBy('cost_price')" :class="{'text-info':this.orderColumn=='cost_price'}">
                                            Pořizovací cena
                                        <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>

                                      </th>
                                      <th width="9%" class="text-right"  :class="{'text-info':this.orderColumn=='car_price'}">
                                            <span @click.prevent="orderBy('car_price')" >Prodejní cena
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                            </span>
                                      </th>
                                      <th width="10%" class="text-right"  :class="{'text-info':this.orderColumn=='car_discount'}">
                                        <span @click.prevent="orderBy('car_discount')">Akční sleva
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true" ></i>
                                        </span>
                                      </th>

                                      <th width="10%" class="text-right" :class="{'text-info':this.orderColumn=='car_bonus'}">
                                            <span @click.prevent="orderBy('car_bonus')">Akční bonus
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                            </span>
                                      </th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(car) in carsSelected" :key="'X' + car.id" >

                                            <td>
                                                <nobr>
                                                    <center>
                                                        <nobr>{{ formatSpz(car.spz) }}</nobr>
                                                    </center>
                                                    <small>{{ car.vin }}</small>
                                                </nobr>
                                            </td>

                                            <td align="left"> 
                                                <a :href="'/cars/'+car.id" target="_new">
                                                {{ car.title }}
                                                </a>
                                            </td>
                                        
                                            <td> 
                                                {{ car.office_abbr }}
                                            </td>
                                        
                                            <td> 
                                                {{ car.typ_zakaznika }}
                                            </td>
                                        
                                            <td> 
                                                {{ car.dni_sklad }}
                                            </td>
                                        
                                            <td> 
                                                {{ car.dni_inzerce }}
                                            </td>
                                            <td> 
                                                {{ formatCurrency(car.car_margin) }}
                                            </td>
                                            <td> 
                                                {{ formatNumber(car.margin_percent,1) }}
                                            </td>

                                            <td> 
                                                {{ formatCurrency(car.skoda_bonus) }}
                                            </td>
                                        
                                            <td> 
                                                {{ formatCurrency(car.cost_price) }}
                                            </td>
                                        
                                            <td> 
                                                    {{ formatCurrency(car.car_price) }}
                                            </td>
                                        
                                            <td> 
                                                {{ formatCurrency(car.car_discount) }}
                                            </td>
                                        
                                            <td> 
                                                {{ car.car_bonus }}

                                            </td>


                                        
                                    </tr>
                                  </tbody>
                              </table>

                </div>  <!-- DATA TABLE END   *****************************************************************    -->


    </div>
</template>



<script>
    export default {

      props: {
        carsSelected:Array, 
       },
      data: function() {
        return {
            asc:true, //pořadí řazení tabulky
            orderColumn:'',
            orderDir: false,

        }
      },

      computed: {

      },
      methods: {


        //seřadí celý set
        orderBy:function(column){
            // alert('ORDER '+column);
            this.orderColumn = column;
            this.orderDir = this.asc;
            this.asc = !this.asc;
            this.carsSelected.sort((a,b)=>(this.asc?1:-1)*((+a[column]>+b[column])?1:-1));
            
        },


      }, // METHODS


      created:function(){

       }, //CREATED

      mounted:function(){
      }, //MOUNTED

      watch:{

 
      }, //WATCH


    }
</script>