<template>
    <div class="mystery-container">
        <div class="mystery-title" @click.stop="open = true">
            <div class="float-right">
                <i class="fas fa-window-maximize" v-show="open" @click.stop="toggle" title="Minimalizovat okno"></i>
                <i class="far fa-window-maximize" v-show="!open" @click.stop="toggle" title="Zvětšit okno"></i>                
            </div>
            Prodejní aktivity

        </div>
        <div class="mystery-body" v-show="open">
            <ul>
                <li :class="{'done':fulfilled(l),'hide':hidden(l)}" v-for="l in list" :key="l.title" @click="$emit('go-to',l.tabNo)">
                        <i class="fas fa-check-square" v-if="fulfilled(l)"></i>
                        <i class="far fa-square" v-if="!constraint(l)&&!fulfilled(l)"></i>
                        {{ l.title }}
                </li>
            </ul>
        </div>
        <div class="mystery-footer">
            {{rating}} %
        </div>
    </div>

</template>



<script>
    export default {

      props: {
          customerData:{},
          demandId:0,
          demandDescription:"",
          contactType:0,
          demandType:0,
          demandPhase:0,
          demandTestDrive:"",
          demandInvitation:"",
          demandFinancing:"",
          demandComission:"",
       },
      data: function() {
          return {
              open:null,
              list: [

                  {
                    title:"Vyberte typ kontaktu",
                    value:10,
                    done:()=>{ return this.contactType>0},
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:0,    //číslo tabu - otevře jej na klik - @TODO DODELAT
                    },

                  {
                    title:"Vyberte typ případu",
                    value:10,
                    done:()=>{ return this.demandType>0},
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:0,    //číslo tabu - otevře jej na klik - @TODO DODELAT
                    },

                    {
                    title:"Zeptejte se na jméno",
                    value:5,
                    done:()=>{ return this.customerData.id>0 || (this.customerData.name>"" && this.customerData.firstname>"")},
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:1,    //číslo tabu - otevře jej na klik - @TODO DODELAT
                  },

                  {
                    title:"Zjistěte bydliště",
                    value:6,
                    done:()=>{ return this.customerData.city>""},
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:1,    
                  },



                  {
                    title:"Zapište kontaktní údaje",
                    value:10,
                    done:()=>{ return this.validEmail(this.customerData.email) && this.validPhone(this.customerData.phone)},
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:1,    
                  },

                  {
                    title:"Vyberte vhodná vozidla z nabídky",
                    value:5,
                    done:()=>{ return (this.$store.getters.getSelectedCars && this.$store.getters.getSelectedCars.length>0) },
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:2,    
                  },

                  {
                    title:"Krátce popište požadavek zákazníka",
                    value:15,
                    done:()=>{ return (this.demandDescription && this.demandDescription.length>10) },
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:3,    
                  },



                  {
                    title:"Zajistěte souhlas se zasláním emailu",
                    value:1,
                    done:()=>{ return (this.customerData.agreement===1) },
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:1,    
                  },


                  {
                    title:"Nabídněte testovací jízdu",
                    value:15,
                    done:()=>{ return this.demandTestDrive && this.demandTestDrive.length>2},
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[1],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[1,2],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:3,    
                  },


                  {
                    title:"Má zákazník zájem o financování?",
                    value:20,
                    done:()=>{ return (this.demandFinancing && this.demandFinancing.length>3) },
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[1],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[1,2],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:3,    
                  },


                  {
                    title:"Pozvěte zákazníka na prodejnu",
                    value:20,
                    done:()=>{ return (this.demandInvitation && this.demandInvitation.length>3) },
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[2,3],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[1,2,3],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:3,    
                  },

                  {
                    title:"Má zákazník zájem o výkup vozu?",
                    value:10,
                    done:()=>{ return (this.demandComission && this.demandComission.length>3) },
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    contactType:[1],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[1,2],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:3,    
                  },

                  {
                    title:"Uložte nabídku",
                    value:2,
                    done:()=>{ return (this.demandId>0) },
                    notShow:()=>{ return false;},    //pokud se má zobrazit až časem
                    demandType:[],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    contactType:[],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                  },

                    {
                    title:"Odešlete nabídku emailem zákazníkovi",
                    value:5,
                    done:()=>{ return this.demandPhase&&this.demandPhase['id']>1},
                    notShow:()=>{ return false},    //pokud se má zobrazit až časem
                    contactType:[1,2],             //pro jaké typy kontaktů - osobní návštěva = 1, tel = 2, email = 3
                    demandType:[1,2,3],             //pro jaké typy případů - 1-Nový vůz - konfigurátor, 2-Ojetý vůz, 3-Výkup, 4-Servis a oprava, 
                    tabNo:3,    
                  },

            ],
        }
      },

    mounted:function(){
      this.open = true;
    },
    methods: {
        toggle:function() {
          console.log('MINI X MAXI', this.open);
          this.open = !this.open;
          console.log('MINI X MAXI', this.open);
        },
        fulfilled: function (item) {
             return item.done();
        },

        //true pokud je tam omezující podmínka
        constraint: function(item) {
            if (item['contactType'] && item['contactType'].length>0) {
                let x = item['contactType'].includes(this.contactType); 
                if (!x) {
                    return true;
                }
            }
            if (item['demandType'] && item['demandType'].length>0) {
                return !item['demandType'].includes(this.demandType);
            }

            return false;
        },
        hidden: function (item) {
            //buď nezobrazovat nebo není vhodným typem
            
             return this.constraint(item) || item.notShow();
        },
    },
        computed: {




            rating:function(){
                let r = 0;
                let c = 0;
                let cnt = 0;  //počet všech
                let sum = 0; //sum = počet součet všech
                this.list.forEach(item => {
                  if (!this.constraint(item)) {
                    cnt++;
                      sum+=item.value;
                      // console.log('ALL' + item.title,sum);
                      if (this.fulfilled(item)) {
                          r += item.value;  //celkem hotovo v %
                          // console.log('FULL' + item.title,r);
                          c++; //celkem počet
                      }
                    }
                });
                // console.log('RATING ALL '+r, sum );
                this.$store.commit('setRating', Math.round(r/sum*100));
                return Math.round(r/sum*100);
            },
        },


    }
</script>


<style>

.mystery-container {
    position: fixed;
    width: 20rem;
    right:1rem;
    bottom:1rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background-color: rgb(211, 243, 215);
    z-index: 2000;
}
.mystery-title {
    background-color:rgb(94, 190, 91);
    color: rgb(128, 0, 0);
    font-weight: 500;
    padding:2px 10px;
}
.mystery-body {
    border-bottom: 1px solid silver;
    border-top: 1px solid silver;
}
.mystery-body ul{
    list-style-type:none;
    padding-left: 1em;
}
.mystery-body li{
    color:red;
}
li.done{
    color: silver ;
    text-decoration-line: line-through;
    /* visibility: hidden; */
}
li.hide{
    display: none;
}

.mystery-footer {
    font-size: .9rem;
    background-color:rgb(200, 238, 215);
    padding: 2px 10px;
    color: steelblue;
    text-align: right;
}
</style>