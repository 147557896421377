//platné pro všechny komponenty

export default {
	data: function () {
		return {
            localStorageVars:[],
            prefix:'ls_',
            finishLoading:true,

            data:null, //pro načítání dat z api
            success:null,
            errors:null,

            customerFlat2object:['title', 'name', 'firstname','phone','id','email','type', 'street', 'email', 'city', 'psc', 'ic',  'rc','agreement', 'framecontract', 'country_id', 'contact_id', 'gender', ],



            demandFlat2object:[],

		};
	},

   methods: {



        /** 
        * @PARAM inputFields Object - seznam vstupních hodnot , jedna z nich MUSÍ být "api_id"
        * @PARAM variableName - do jaké proměnné nahrát data - jinak standardně do apiData, lze nastavit WATCH na proměnnou
        *           pokud variableName je objekt, pak nahraje do každé proměnné {var_name: name} tj. this.[varName] = response.data.data[name]
        *           výjimka je varName = 'data', pak vrátí celý datový  objekt vč. errors apod. (=  response.data), 'success' vrátí stav, 'errors' chybové hlášky
        *
        * @PARAM finishedClosure - co má proběhnout po ukončení
        * @RETURN vrátí objekt - s errors, success-true, input - seznam vstupních parametrů, message
        *           pokud dojde k chybě, v atributu "errors"
         **/
        getApiData: function (inputFields, variableName = 'apiData', finishedClosure = ()=>{}) {
    
            //this[variableName] = {};
            this.$store.dispatch('startLoading');
            console.log('GLOBAL - API POZADAVEK',inputFields);

            axios.defaults.withCredentials = true;
            axios.post('/api/request', inputFields)
                    .then(response => {
                        
                        // console.log('VRACENA DATA',response.data);
                        //console.log(response.data.message);
                        //console.log(response);
                        // console.log('RESPONSE (global.js): ',response.data);
                        if (variableName>"") {
                            if (typeof variableName === 'string') {
                                this[variableName] = response.data;
                            } else if (typeof variableName === 'object') {
                                if (Object.keys(variableName)!==undefined) {
                                    //pro všechny položky 
                                    for (var key of Object.keys(variableName)) {

                                        if (variableName[key]==='data') {
                                            this[key] = response.data;
                                        } else if (variableName[key]==='success') {                                           
                                            this[key] = response.data.success;
                                        } else if (variableName[key]==='errors') {
                                            this[key] = response.data.errors;
                                        } else {
                                            this[key] = response.data.data[variableName[key]];
                                        }
                                    }
                                    //získaný process ID uloží
                                    console.log('GLOBAL - VRACENA DATA',response.data);
                                }
                            }
                        } 
                        finishedClosure();
                        this.$store.dispatch('finishLoading');
                    })
                    .catch(error => {
                        console.log(error.response);
                        finishedClosure();
                        this.$store.dispatch('finishLoading');
                    });
                    

        },




        formatCurrency: function (number) {
            number = Math.floor(number);
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        },

        formatNumber: function (number, decimals) {
            const pow = Math.pow(10, decimals);
            number = Math.round(number*pow)/pow;
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        },

        formatSpz: function (spz) {
            if (spz && spz!==null) {
                return spz.substr(0,3) + ' ' + spz.substr(3,6);
            }
        },


        formatDate: function (date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
          },

        today:function() {
            var today = new Date();
            var date = today.getDate()+'.'+(today.getMonth()+1)+'.'+today.getFullYear();
            return date;
        },


        //porovná 2 pole
        compareArrays: function(array1, array2) {
            if (!array1 || !array2)
            return false;
        
            // compare lengths - can save a lot of time 
            if (array1.length != array2.length)
                return false;
        
            for (var i = 0, l=array1.length; i < l; i++) {
                // Check if we have nested arrays
                if (array1[i] instanceof Array && array2[i] instanceof Array) {
                    // recurse into the nested arrays
                    if (!this.compareArrays(array1[i],array2[i])) {
                        return false;       
                    }
                }           
                else if (!array1.includes(array2[i])) { 
                    // Warning - two different object instances will never be equal: {x:20} != {x:20}
                    return false;   
                }           
            }       
            return true;
        },

            //úvodní metody - k předělání a někam pryč
            validPhone: function(phone) {
                return phone>"";
            },

            validEmail: function(email) {
                return email>"";
            },

            validStringNotEmptyMaxSize: function(str, minLength = 0, maxLength = 9999) {
                if (str) {
                    console.log('KONTROLUJI retezec min ' + minLength+': max'+ maxLength +' / má '+str.length, (str>"" && str.length<maxLength && str.length>=minLength));
                    return (str>"" && str.length<maxLength && str.length>=minLength);
                }
            },




        //test zda existuje local storage
        isLocalStorage:function (){
            var test = 'test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
            } catch(e) {
                return false;
            }
        },

        //obnoví jednu konkrétní položku
        restoreValue: function (valueName, prefix) {
            if (localStorage.getItem(prefix + valueName)===null) {return;}
            
            let v = localStorage.getItem(prefix + valueName);
            // console.log(valueName, typeof this[valueName]);
            if (Array.isArray(this[valueName]) || typeof this[valueName] === 'object' || typeof this[valueName] === "boolean") {
                if (v>"" && v!=="undefined") {
                    // console.log(valueName, v);
                    this[valueName] = JSON.parse(v);
                } else {
                    this[valueName] = v;
                }
            } else {
                this[valueName] = v;
            }
        },


        //obnoví jednu konkrétní položku
        //prefix je pro odlišení mezi komponentami . nepovinný 
        //pokud obsadí value pak uloží tuto hodnotu
        storeValue: function (valueName, prefix, value = null) {
            // console.log('STORE:' + valueName, this[valueName]);
            let val;
            if (value===null) {
                val = this[valueName];
            } else if (value===undefined) {
                val = null;
            } else  {
                val = value;
            }
            //console.log('TAK val:' + val,value);

            if ((Array.isArray(val) || typeof val === 'object' || typeof val === "boolean")) { //&& val !== null
                localStorage.setItem(prefix + valueName, JSON.stringify(val));
            } else {
                localStorage.setItem(prefix + valueName, val);
            }
        },

        //obnoví jednu konkrétní položku
        getValue: function (valueName, prefix) {
            if (localStorage.getItem(prefix + valueName)===null) {return;}
            
            let v = localStorage.getItem(prefix + valueName);
            // console.log(valueName, typeof this[valueName]);
            if (Array.isArray(this[valueName]) || typeof this[valueName] === 'object' || typeof this[valueName] === "boolean") {
                if (v>"" && v!=="undefined") {
                    // console.log(valueName, v);
                    v = JSON.parse(v);
                }
                
            }
            return v;
        },


        //obnoví  hodnoty do localstorage - seznam hodnot definován v komponentě pod localStorageVars
        localStorageRestore:function(localStorageVars, prefix) {
            // console.log('RESTORE',localStorageVars);
            localStorageVars.forEach((item)=>{
            	this.restoreValue(item, prefix);
            });
        },

        //uloží hodnoty do localstorage - seznam hodnot definován v komponentě pod localStorageVars
        localStorageSave:function(localStorageVars, prefix) {

            if (this.isLocalStorage()) {  /* function to detect if localstorage is supported*/
                localStorageVars.forEach((item)=>{
                	this.storeValue(item, prefix);
                });
            }
         },


        //převede plochá data (s prefixem např. "this.customer_XXX" na objekt) - variables je seznam polí ['name','surname',...]
         flat2object:function(variables, prefix, source){
            let o = {};
            if(variables && variables.length>0) {
                variables.forEach(item=>{
                    o[item] = source[prefix+item];
                });
            }
            return o;
         },


        //převede objekt do this na plochá data (např. "this.customer_XXX" z objektu) - variables je seznam polí ['name','surname',...]
        object2flat:function(variables, prefix, source){
        if(variables && variables.length>0 && source) {
            variables.forEach(item=>{
                // console.log('2FLAT: '+prefix+item, source[item]);

                this[prefix+item] = source[item];
            });
        }
        },
        
   },
   // created: function () {
   // },

}